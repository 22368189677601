import { useState, useEffect } from "react"
import { Modal, Input, message } from "antd"
import CopyToClipboard from "react-copy-to-clipboard"
import { MModalList, MModalItem } from "@/component"
import { useDebounce } from "@/hook"
import { theString } from "@/util"
import { useSessionStore, useLocalStore } from "@/store"
import { IMAGE } from "@/assets"
import "./styles.less"

const IndexComponent = ({ 
  open, 
  setOpen, 
  title, 
  options, 
  selidx, 
  setSelidx, 
  toConnect, 
  isSearch 
}) => {
  const [value, setValue] = useState("")
  const debounceValue = useDebounce(value)
  
  useEffect(() => {
    console.log(debounceValue)
  }, [debounceValue])

  return (
    <Modal 
      open={open} 
      title={title}
      closeIcon={<img src={IMAGE.close} alt="" draggable="false" className="close_button" />}
      onCancel={() => setOpen(false)}
      afterClose={() => setValue("")}
      centered
      wrapClassName="m_modal"
      classNames={{
        content: "m_modal_content",
        header: "m_modal_header",
        body: "m_modal_body",
        footer: "m_modal_footer",
      }}
    >
      {
        isSearch && <div className="m_modal_search">
          <img src={IMAGE.search} alt="" draggable="false" />
          <Input 
            block="true" 
            placeholder="Search" 
            value={value}
            onChange={(e) => {
              const _value = e.target.value
              setValue(_value)
            }}
          />
        </div>
      }
      <MModalList>
      {
        options.map((option, index) => {
          return <MModalItem 
            name={option._label} 
            icon={option._icon}
            selected={selidx === index}
            key={index} 
            onClick={() => {
              !!setSelidx && setSelidx(index)
              !!toConnect && toConnect(option.key)
              setOpen(false)
            }}
          />
        })
      }
      </MModalList>
    </Modal>
  )
}

export default IndexComponent

export const WalletIndexComponent = ({ open, setOpen, onDisconnect }) => {
  const { sState } = useSessionStore()
  const { lState } = useLocalStore()
  const { sMetaMaskAddress, sChainId } = sState
  const { lNetworkOptions } = lState

  return (
    <Modal 
      open={open} 
      title="Wallet"
      closeIcon={<img src={IMAGE.close2} alt="" draggable="false" className="close_button" />}
      onCancel={() => setOpen(false)}
      centered
      wrapClassName="m_modal"
      classNames={{
        content: "m_modal_content",
        header: "m_modal_header m_wallet_modal_header",
        body: "m_modal_body m_wallet_modal_body",
        footer: "m_modal_footer",
      }}
    >
      {
        !!lNetworkOptions && <img src={lNetworkOptions.find(obj => Number(obj.chainId) === Number(sChainId))?.chainIcon || IMAGE.error} alt="" draggable="false" className="network_icon" />
      }
      <p className="wallet_address">{ theString.ellipsisMiddle(sMetaMaskAddress, 10) }</p>
      <p className="wallet_name">Connect with MetaMask</p>
      <div className="wallet_buttons">
        <CopyToClipboard
          text={ sMetaMaskAddress }
          onCopy={() => {
            message.success("Copied successfully")
            setOpen(false)
          }}
        >
          <div>
            <img src={IMAGE.copy} alt="" draggable="false" />
            <p>Copy Address</p>
          </div>
        </CopyToClipboard>
        <div onClick={() => {
          onDisconnect()
          setOpen(false)
        }}>
          <img src={IMAGE.logout} alt="" draggable="false" />
          <p>Disconnect</p>
        </div>
      </div>
    </Modal>
  )
}