import { useClass } from "@/hook"
import { IMAGE } from "@/assets"
import "./styles.less"

const IndexComponent = ({ 
  data,
  lpName,
  isFarm,
  isPool
 }) => {
  const { classNames } = useClass()

  return (
    <div className={classNames([
      "m_token",
      (isFarm || isPool) && "m_farm_token"
    ])}>
      <div className="m_token_icons">
      {
        data.map((item, index) => {
          return <img 
            src={item.icon || IMAGE.default_token} 
            alt="" 
            draggable="false" 
            key={index} 
          />
        })
      }
      </div>
      <p className="m_token_name">{ 
        isFarm ? lpName : data.map(obj => obj.symbol).join("-") 
      }</p>
    </div>
  )
}

export default IndexComponent