import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { 
  RHome,
  RBridge,
  RPools,
  RPool1,
  RPool2,
  RFarms,
  RFarm,
  RStakings,
  RNotFound 
} from "@/page"
import { 
  MHead, 
  MTabbar
} from "@/component"
import "@/i18n"
import "./App.less"

const App = () => {

  return (
    <Router>
      <MHead />
      <Switch>
        <Route exact path="/" component={RHome} />
        <Route path="/bridge" component={RBridge} />
        <Route path="/pools" component={RPools} />
        <Route path="/pool1" component={RPool1} />
        <Route path="/pool2" component={RPool2} />
        <Route path="/farms" component={RFarms} />
        <Route path="/farm" component={RFarm} />
        <Route path="/stakings" component={RStakings} />
        <Route path="/*" component={RNotFound} />
      </Switch>
      <MTabbar />
    </Router>
  )
}

export default App