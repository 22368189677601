import { theWindow, theString } from "@/util"
import { IMAGE } from "@/assets"
import "./styles.less"

const IndexComponent = ({ chain }) => {

  const icon = 
    chain === "Arbitrum" ? 
      IMAGE.arbitrum 
    : chain === "Binance Smart Chain" ?
      IMAGE.bnb
    : 
      IMAGE.default_chain
  
  const color = 
    chain === "Arbitrum" ? 
      theWindow.getComputedStyle("--blue2")
    : chain === "Binance Smart Chain" ?
      theWindow.getComputedStyle("--yellow")
    : 
      theWindow.getComputedStyle("--gray")

  return (
    <div className="m_chain" style={{ backgroundColor: theString.colorHexToRgba(color, 0.08) }}>
      <img src={icon} alt="" draggable="false" />
      <p style={{ color: color }}>{ chain }</p>
    </div>
  )
}

export default IndexComponent