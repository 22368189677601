import { useClass } from "@/hook"
import "./styles.less"

const IndexComponent = ({icon, label, isFlex, isSelected, onClick}) => {
  const { classNames } = useClass()

  return (
    <div className={classNames([
      "m_button",
      isSelected && "m_button_active"
    ])} style={{
      flex: isFlex ? 1 : 'none'
    }} onClick={() => {
      !!onClick && onClick()
    }}>
      {
        !!label && <p>{ label }</p>
      }
      {
        !!icon && <img src={icon} alt="" draggable="false" />
      }
    </div>
  )
}

export default IndexComponent