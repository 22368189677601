const theWindow = {

  getQueryVariable(variable) {
    var query = window.location.search.substring(1)
    var vars = query.split("&")
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=")
      if (pair[0] === variable) {
        return decodeURIComponent(pair[1])
      }
    }
    return false
  },

  getComputedStyle(color) {
    return getComputedStyle(document.documentElement).getPropertyValue(color).trim()
  },

  isWeChatBrowser() {
    const userAgent = navigator.userAgent.toLowerCase()
    return userAgent.includes('micromessenger')
  },

  isQuarkBrowser() {
    return /Quark/i.test(navigator.userAgent);
  },

  isCantPlayMP4() {
    return this.isWeChatBrowser() || this.isQuarkBrowser()
  }

}

export default theWindow