/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { useSessionStore, useLocalStore } from "@/store"
import { Button } from "antd"
import { MContainer, MModal, MWalletModal } from "@/component"
import { useConstant, useRoute, useClass, useEthers, useInfo } from "@/hook"
import { theString } from "@/util"
import { IMAGE } from "@/assets"
import "./styles.less"

const IndexComponent = () => {
  const location = useLocation()
  const { sState } = useSessionStore()
  const { lState } = useLocalStore()
  const { classNames } = useClass()
  const { TABBARS, TABBARHOME, WALLETS } = useConstant()
  const { connectWallet, disconnectWallet } = useEthers()
  const { initNetworkOptions } = useInfo()
  const { push } = useRoute()
  const { pathname } = location
  const { sMetaMaskAddress, sChainId } = sState
  const { lNetworkOptions } = lState
  const [connectOpen, setConnectOpen] = useState(false)
  const [walletOpen, setWalletOpen] = useState(false)

  useEffect(() => {
    document.body.scrollIntoView()
  }, [location])

  useEffect(() => {
    initNetworkOptions()
  }, [])

  return (
    <MContainer 
      className={classNames([
        "m_head_fixed",
        ![...TABBARHOME, ...TABBARS].some(item => item.route === pathname) && "m_head_not_tabbar"
      ])}
      render={() => (
        <div className="m_head">
          <img src={IMAGE.logo} alt="" draggable="false" className="m_head_logo" onClick={() => push("/")} />
          <div className="m_head_tabbar">
          {
            TABBARS.map((tabbar, index) => {
              return <p key={index} className={classNames([
                pathname !== "/" && pathname.startsWith(`/${tabbar.tag}`) && "active"
              ])} onClick={() => {
                if (JSON.parse(process.env.REACT_APP_DEBUG) || !!tabbar.visible) {
                  push(tabbar.route)
                }
              }}>{ tabbar.label }</p>
            })
          }
          </div>
          {
            !sMetaMaskAddress ? 
              <Button className="m_head_connect" onClick={() => connectWallet()}>Connect Wallet</Button>
            : 
              <div className="m_head_connected" onClick={() => setWalletOpen(true)}>
                {
                  !!lNetworkOptions && <img src={lNetworkOptions.find(obj => Number(obj.chainId) === Number(sChainId))?.chainIcon || IMAGE.error} alt="" draggable="false" />
                }
                <p>{ theString.ellipsisMiddle(sMetaMaskAddress, 10) }</p>
              </div>
          }
          <MModal 
            open={connectOpen}
            setOpen={setConnectOpen}
            title="Connect Wallet"
            options={WALLETS}
            toConnect={(_) => connectWallet()}
          />
          <MWalletModal
            open={walletOpen}
            setOpen={setWalletOpen}
            onDisconnect={() => disconnectWallet()}
          />
        </div>
      )}
    />
  )
}
export default IndexComponent
