/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react"
import { withRouter } from "react-router-dom"
import { message } from "antd"
import { MContainer, MForm, MFormItem, MFormItemValue } from "@/component"
import { useWeb3Request } from "@/hook"
import { ETHERS } from "@/ethers"
import { useSessionStore } from "@/store"
import { mathematics } from "@/util"
import { ABI, IMAGE } from "@/assets"
import "./styles.less"

const IndexPage = ({ history }) => {
  const DATA = history.location.state
  const { sState } = useSessionStore()
  const { getWeb3RequestNotError, postWeb3Request } = useWeb3Request()
  const { sMetaMaskAddress } = sState
  const [reload, setReload] = useState(false)
  const [tokens, setTokens] = useState([])
  const [poolTVL, setPoolTVL] = useState(0)
  const [totalStakeValue, setTotalStakeValue] = useState(0)
  const [estDailyYield, setEstDailyYield] = useState(0)
  const [apr, setApr] = useState(0)
  const [shareOfPool, setShareOfPool] = useState(0)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const _tokens = DATA.tokens.map(obj => {
      return {
        ...obj,
        amount: "",
        tokenBalance: 0,
        tokenPrice: 0,
        stakedNumber: 0,
      }
    })
    setTokens(_tokens)
  }, [DATA])

  useEffect(() => {
    const getTokensData = async () => {
      const promises = tokens.map(async (token) => {
        const priceResp = await getWeb3RequestNotError(
          DATA.contractAddress, 
          ABI.STAKINGPOOL, 
          "getTokenPrice", 
          [token.contractAddress]
        )
        const balanceResp = await getWeb3RequestNotError(
          token.contractAddress, 
          ABI.ERC20, 
          "balanceOf", 
          [sMetaMaskAddress]
        )
        const price = priceResp === null ? 0 : ETHERS.format(priceResp)
        const balance = balanceResp === null ? 0 : ETHERS.format(balanceResp)
        return {
          ...token, 
          tokenPrice: price,
          tokenBalance: balance
        }
      })
      const _tokens = await Promise.all(promises)
      const addressArg = getAddressArg()
      const stakedResp = await getWeb3RequestNotError(
        DATA.contractAddress, 
        ABI.STAKINGPOOL, 
        "getUserStakedTokenAmountFromPool", 
        [sMetaMaskAddress, addressArg]
      )
      if (stakedResp === null) {
        setTokens(_tokens)
      } else {
        const __tokens = _tokens.map((_token, index) => {
          return { ..._token, stakedNumber: ETHERS.format(stakedResp[index]) }
        })
        setTokens(__tokens)
      }
    }
    tokens.length !== 0 && getTokensData()
  }, [tokens.length, sMetaMaskAddress, reload])

  const onChange = (idx, value, price) => {
    const amount = !!value ? Number(value) : 0
    const total = Number(price) * Number(amount)
    const _tokens = tokens.map((token, index) => {
      return {
        ...token, 
        amount: 
          !value ?
            value
          : idx === index ? 
            value
          : token.tokenPrice === 0 ?
            ""
          : 
            mathematics.ceil(Number(total) / Number(token.tokenPrice), 6),
      }
    })
    setTokens(_tokens)
  }

  const getAddressArg = () => {
    const contractAddresses = tokens.map(token => token.contractAddress)
    while (contractAddresses.length < 5) {
      contractAddresses.push("0x0000000000000000000000000000000000000000")
    }
    return contractAddresses
  }

  const getAmountArg = () => {
    const amounts = tokens.map(token => ETHERS.bignumber(token.amount, token.decimal))
    while (amounts.length < 5) {
      amounts.push(ETHERS.bignumber(0))
    }
    return amounts
  }

  const onAdd = async () => {
    setLoading(true)
    // const promises = tokens.map(async (token) => {
    //   const resp = await postWeb3Request(
    //     token.contractAddress, 
    //     ABI.ERC20, 
    //     "approve", 
    //     [DATA.contractAddress, ETHERS.bignumber(Math.ceil(token.amount), token.decimal)]
    //   )
    //   return resp
    // })
    // const result = await Promise.all(promises)
    const result = []
    let index = 0
    while (index < tokens.length) {
      const token = tokens[index]
      const resp = await postWeb3Request(
        token.contractAddress, 
        ABI.ERC20, 
        "approve", 
        [DATA.contractAddress, ETHERS.bignumber(Math.ceil(token.amount), token.decimal)]
      )
      result.push(resp)
      index ++
    }
    const containsNull = result.some(obj => obj === null)
    if (containsNull) {
      setLoading(false)
    } else {
      const addressArg = getAddressArg()
      const amountArg = getAmountArg()
      const resp = await postWeb3Request(
        DATA.contractAddress, 
        ABI.STAKINGPOOL, 
        "stake", 
        [addressArg, amountArg]
      )
      setLoading(false)
      if (resp === null) return
      setReload(!reload)
      message.success("Add successfully")
    }
  }

  useEffect(() => {
    const getTotalStakeValue = async () => {
      const resp = await getWeb3RequestNotError(
        DATA.contractAddress, 
        ABI.STAKINGPOOL, 
        "totalStakeValue", 
        []
      )
      if (resp === null) return
      setTotalStakeValue(ETHERS.format(resp))
    }
    getTotalStakeValue()
  }, [reload])

  useEffect(() => {
    const getPoolTVL = async () => {
      const addressArg = getAddressArg()
      const resp = await getWeb3RequestNotError(
        DATA.contractAddress, 
        ABI.STAKINGPOOL, 
        "getPoolTVL", 
        [addressArg]
      )
      if (resp === null) return
      setPoolTVL(ETHERS.format(resp))
    }
    tokens.length !== 0 && getPoolTVL()
  }, [tokens.length, reload])

  useEffect(() => {
    if (tokens.length === 0) return
    if (totalStakeValue === 0) return
    const currentTokenValue = tokens.reduce((sum, _token) => {
      return sum + (Number(_token.tokenPrice || "0") * Number(_token.amount || "0"))
    }, 0)
    const multiplier = Number(DATA.multiplier || "1")
    const _estDailyYield = ((currentTokenValue * multiplier) / totalStakeValue) * 690000
    setEstDailyYield(_estDailyYield)
    const _apr = (_estDailyYield * 365) / currentTokenValue
    setApr(_apr)
    const _shareOfPool = poolTVL === 0 ? 1 : (currentTokenValue * multiplier) / poolTVL
    setShareOfPool(_shareOfPool)
  }, [tokens, totalStakeValue, poolTVL])

  return (
    <div className="p_pool2">
      <MContainer
        render={() => (
          <MForm 
            name="Pool"
            loading={loading}
            tokens={tokens}
            setTokens={setTokens}
            onChange={onChange}
            onAdd={onAdd}
            formItems={[
              <MFormItem 
                key={0}
                label="Staked" 
                valueDom={
                  <MFormItemValue data={tokens.map((item) => {
                    return {
                      icon: item.icon || IMAGE.default_token, 
                      value: `${mathematics.localeString(Number(item.stakedNumber || "0") + Number(item.amount || "0"))} ${item.symbol}`
                    }
                  })} />
                } 
              />,
              <MFormItem 
                key={1}
                label="Est. Daily Yield" 
                valueDom={
                  <MFormItemValue data={[{
                    icon: IMAGE.tsp, 
                    value: `${mathematics.localeString(estDailyYield)} TSP`
                  }]} />
                } 
              />,
              <MFormItem
                key={2} 
                label="APR" 
                value={`${mathematics.localeString(apr * 100)}%`} 
              />,
              <MFormItem 
                key={3} 
                label="Share of Pool" 
                value={`${mathematics.localeString(shareOfPool * 100)}%`}  
              />
            ]}
          />
        )}
      />
    </div>
  )
}
export default withRouter(IndexPage)

/**
   * 当前的币种价值: TokenA x PriceA + TokenB x PriceB
   * staked: 已有的Token数量（调用合约 getUserStakedTokenAmountFromPool 方法） + 将要质押的token数量
   * earned：当前未结算收益 (调用合约getProfit方法)，单位TSP
   * Est. Daily Yield: ((当前的币种价值 x weight) / totalStakeValue * 69万
   * APR: ((Est. Daily Yield) * 365) / 当前的币种价值
   * Share of Pool: (当前的币种价值 x weight) / getPoolTVL
**/ 