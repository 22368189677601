import { ethers } from "ethers"
import { storage } from "@/util"

const connect = async () => {
  // 检查 window.ethereum 是否可用
  if (typeof window.ethereum !== "undefined") {
    try {
      // 请求用户授权连接钱包
      const accounts = await window.ethereum.request({ method: "eth_requestAccounts" })
      // 创建提供者
      const provider = new ethers.providers.Web3Provider(window.ethereum)
      // 创建签名者
      const signer = provider.getSigner()
      storage.setl("cachedProvider", true)
      console.log("钱包连接成功，账户地址:", accounts[0])
      return { provider, signer }
    } catch (error) {
      console.error("用户拒绝连接钱包:", error)
    }
  } else {
    console.log("请安装 MetaMask!")
  }
}

export default connect