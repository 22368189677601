import "./styles.less"

const IndexComponent = ({ 
  label, 
  value, 
  valueDom 
}) => {

  return (
    <div className="m_form_item">
      <p className="m_form_item_label">{ label }</p>
      {
        !!valueDom ?
          valueDom
        :
          <p className="m_form_item_value">{ value }</p>
      }
    </div>
  )
}
IndexComponent.displayName = "m_form_item"

export default IndexComponent

export const IndexValueComponent = ({ data }) => {

  return (
    <div className="m_form_value">
    {
      data.map((item, index) => {
        return <div className="m_form_value_item" key={index}>
          <div />
          <img src={item.icon} alt="" draggable="false" />
          <p>{ item.value }</p>
        </div>
      })
    }
    </div>
  )
}