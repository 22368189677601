import { Button } from "antd"
import { mathematics } from "@/util"
import moment from "moment"
import { useClass } from "@/hook"
import { IMAGE } from "@/assets"
import "./styles.less"

export const StakingsIndexComponent = ({
  value,
  day,
  lockedTime,
  claimTime,
  earned,
  multiplier,
  status,
  loading,
  onClick
}) => {
  const { classNames } = useClass()
  const isExpire = moment.unix(lockedTime).add(day, "days").unix() - moment().unix() <= 0 

  return (
    <div className="m_item">
      <div className="m_item_row">
        <div className="m_item_label">
          <img src={IMAGE.stakings_icon1} alt="" draggable="false" />
          <p>TSP Locked</p>
        </div>
        <div className="m_item_label">
          <p>Unlock Until</p>
          <img src={IMAGE.stakings_icon2} alt="" draggable="false" />
        </div>
      </div>
      <div className="m_item_row">
        <p className="m_item_value">{ mathematics.localeString(value) }</p>
        <img src={status === 0 ? IMAGE.arrow3 : IMAGE.tick3} alt="" draggable="false" className="m_item_icon" />
        <p className={classNames([
          "m_item_value",
          !!day && "m_item_day"
        ])}>{`${mathematics.localeString(day)} days`}</p>
      </div>
      <div className="m_item_row">
        <p className="m_item_time">{ moment.unix(lockedTime).format("MMM D, YYYY [at] h:mm A") }</p>
        <p className="m_item_time">{ moment.unix(lockedTime).add(day, "days").format("MMM D, YYYY [at] h:mm A") }</p>
      </div>
      <div className="m_item_line" />
      <div className="m_item_row">
        <div className="m_item_content">
          <p className="m_item_content_key">TSP Earned</p>
          <p className="m_item_content_value">{ mathematics.localeString(earned) }</p>
        </div>
        <div className="m_item_content">
          <p className="m_item_content_key">Multiplier</p>
          <p className="m_item_content_value">{`${mathematics.localeString(multiplier)}x`}</p>
        </div>
        <div className="m_item_content">
          <p className="m_item_content_key">Duration</p>
          <p className="m_item_content_value">{`${(status === 0 ? moment() : moment.unix(claimTime)).diff(moment.unix(lockedTime), "days")} days`}</p>
        </div>
      </div>
      <Button 
        disabled={!(status === 0 && isExpire)} 
        className="m_item_button" 
        loading={loading}
        onClick={() => onClick()}
      >Withdraw</Button>
    </div>
  )
}
