import React, { isValidElement } from "react"
import "./styles.less"

const IndexComponent = (props) => {

  const chilrenElement = []
  React.Children.forEach(props.children, (child, index) => {
    const { type } = child || {}
    if (isValidElement(child) && type.displayName === "m_form_item") {
      const _child = React.cloneElement(child, {
        key: index,
      })
      chilrenElement.push(_child)
    }
  })

  return (
    <div className="m_form_list">{ chilrenElement }</div>
  )
}

export default IndexComponent