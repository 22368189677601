/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react"
import { Button } from "antd"
import { 
  MFormInput, 
  MFormList,
  MBridgeFormInput,
  MStakingsFormInput,
  MButton,
  MTokens,
  MChain
} from "@/component"
import { useRoute, useClass } from "@/hook"
import { mathematics } from "@/util"
import { IMAGE } from "@/assets"
import "./styles.less"

const IndexComponent = ({ 
  name, 
  loading, 
  tokens, 
  onChange, 
  onAdd,
  formItems
}) => {
  const { back } = useRoute()
  const { classNames } = useClass()
  const [disabled, setDisabled] = useState(false)

  useEffect(() => {
    const isEmpty = tokens.some(obj => !obj.amount || Number(obj.amount) === 0)
    const isDanger = tokens.some(obj => !!obj.amount && Number(obj.amount) > mathematics.floor(obj.tokenBalance))
    const _disabled = isEmpty || isDanger
    setDisabled(_disabled)
  }, [tokens])

  return (
    <div className="m_form">
      <div className="form_head">
        <img src={IMAGE.arrow2} alt="" draggable="false" onClick={() => back()} />
        <p className="name">{ name }</p>
      </div>
      <p className="form_list_title">Add</p>
      <div className="form_list">
      {
        tokens.map((item, index) => {
          return <MFormInput 
            key={index} 
            token={item.symbol}
            icon={item.icon}
            tokenPrice={item.tokenPrice}
            tokenBalance={item.tokenBalance}
            value={item.amount}
            setValue={(_) => onChange(index, _, item.tokenPrice)}
          />
        }) 
      }
      </div>
      <MFormList>
      {
        formItems
      }
      </MFormList>
      <div className="form_button_container">
        <Button
          disabled={disabled}
          loading={loading}
          className={classNames([
            "form_button",
            disabled && "form_button_disable"
          ])}
          onClick={onAdd}
        >Add</Button>
      </div>
    </div>
  )
}

export default IndexComponent

export const BridgeIndexComponent = ({ 
  fromOption, 
  toOption, 
  tspBalance,
  amountValue,
  setAmountValue,
  addressValue,
  setAddressValue,
  onExchange, 
  onTransfer, 
  loading,
  onSelectNetwork,
}) => {
  const { classNames } = useClass()
  const [isDisabled, setIsDisabled] = useState(false)
  const [isDanger, setIsDanger] = useState(false)
  const isInvalid = fromOption?.chainFullName === toOption?.chainFullName

  useEffect(() => {
    const _isDisabled = !amountValue || isInvalid || isDanger
    setIsDisabled(_isDisabled)
  }, [amountValue, isInvalid, isDanger])
  
  return (
    <div className="m_form m_form_bridge">
      <div className="form_head">
        <p>Bridge</p>
      </div>
      <div className="form_list">
        <MBridgeFormInput 
          option={fromOption}
          balance={tspBalance}
          value={amountValue}
          setValue={setAmountValue}
          isFrom
          onExchange={onExchange}
          onSelectNetwork={onSelectNetwork}
          isDanger={isDanger}
          setIsDanger={setIsDanger}
        />
        <MBridgeFormInput 
          option={toOption}
          value={amountValue}
          addressValue={addressValue}
          setAddressValue={setAddressValue}
          onSelectNetwork={onSelectNetwork}
        />
      </div>
      <div className="form_button_container">
        <Button
          disabled={isDisabled}
          loading={loading}
          className={classNames([
            "form_button",
            (isDisabled) && "form_button_disable"
          ])}
          onClick={() => onTransfer()}
        >Transfer</Button>
      </div>
    </div>
  )
}
export const Pool1IndexComponent = ({
  name, 
  tokens, 
  chainFullName,
  tvl,
  multiplier,
  apr,
  stakedNumber,
  earnedNumber,
  estDailyYield,
  shareOfPool,
  removeLoading,
  claimLoading,
  onAdd, 
  onRemove,
  onClaim,
}) => {
  const { back } = useRoute()
  const { classNames } = useClass()

  return (
    <div className="m_form m_farm_form m_pool1_form">
      <div className="form_head">
        <img src={IMAGE.arrow2} alt="" draggable="false" onClick={() => back()} />
        <p className="name">{ name }</p>
      </div>
      <div className="form_tokens">
        <MTokens data={tokens} isPool />
        <MChain chain={chainFullName} />
      </div>
      <div className="form_datas">
        <div className="form_data">
          <p className="form_data_key">TVL</p>
          <p className="form_data_value">{`$${mathematics.localeString(tvl)}`}</p>
        </div>
        <div className="form_data">
          <p className="form_data_key">Multiplier</p>
          <p className="form_data_value">{`${multiplier || 1}x`}</p>
        </div>
        <div className="form_data">
          <p className="form_data_key">APR</p>
          <p className="form_data_value">{`${mathematics.localeString(apr * 100)}%`}</p>
        </div>
      </div>
      <div className="m_farm_form_item">
        <p className="item_name">Staked</p>
        <p className="item_amount">{ mathematics.localeString(stakedNumber) }</p>
        <div className="item_buttons">
          <Button 
            loading={removeLoading}
            disabled={stakedNumber === 0}
            icon={<img src={IMAGE.remove} alt="" draggable="false" />} 
            className={classNames([
              "half_button",
              stakedNumber === 0 && "half_button_disable"
            ])}
            onClick={onRemove}
          >Remove</Button>
          <Button
            icon={<img src={IMAGE.add2} alt="" draggable="false" />}
            className="half_button half_button_blue" 
            onClick={onAdd}
          >Add</Button>
        </div>
      </div>
      <div className="form_datas form_datas2">
        <div className="form_data">
          <p className="form_data_key">Earned</p>
          <p className="form_data_value">{`${mathematics.localeString(earnedNumber)} TSP`}</p>
        </div>
        <div className="form_data">
          <p className="form_data_key">Est. Daily Yield</p>
          <p className="form_data_value">{`${mathematics.localeString(estDailyYield)} TSP`}</p>
        </div>
        <div className="form_data">
          <p className="form_data_key">Share of Pool</p>
          <p className="form_data_value">{`${mathematics.localeString(shareOfPool * 100)}%`}</p>
        </div>
      </div>
      <div className="claim_button_container">
        <Button 
          loading={claimLoading}
          disabled={earnedNumber === 0}
          className={classNames([
            "claim_button",
            earnedNumber === 0 && "claim_button_disable"
          ])}
          onClick={onClaim}
        >Claim TSP Yield</Button>
      </div>
      
    </div>
  )
}

export const FarmIndexComponent = ({
  name, 
  tokens, 
  stakedNumber,
  earnedNumber, 
  removeLoading,
  addLoading,
  claimLoading,
  onAdd, 
  onRemove,
  onClaim,
  formItems,
  lpBalance,
  lpName,
}) => {
  const { back } = useRoute()
  const { classNames } = useClass()

  return (
    <div className="m_form m_farm_form">
      <div className="form_head">
        <img src={IMAGE.arrow2} alt="" draggable="false" onClick={() => back()} />
        <p className="name">{ name }</p>
      </div>
      <div className="form_tokens">
        <MTokens data={tokens} lpName={lpName} isFarm />
      </div>
      <div className="m_farm_form_item">
        <p className="item_name">Staked</p>
        <p className="item_amount">{ mathematics.localeString(stakedNumber) }</p>
        <div className="item_buttons">
          <Button 
            loading={removeLoading}
            disabled={stakedNumber === 0}
            icon={<img src={IMAGE.remove} alt="" draggable="false" />} 
            className={classNames([
              "half_button",
              stakedNumber === 0 && "half_button_disable"
            ])}
            onClick={onRemove}
          >Remove</Button>
          <Button 
            loading={addLoading}
            disabled={lpBalance === 0}
            icon={<img src={IMAGE.add2} alt="" draggable="false" />}
            className={classNames([
              "half_button",
              lpBalance === 0 && "half_button_disable"
            ])} 
            onClick={onAdd}
          >Add</Button>
        </div>
      </div>
      <div className="m_farm_form_item">
        <p className="item_name">TSP Earned</p>
        <p className="item_amount">{ mathematics.localeString(earnedNumber) }</p>
        <div className="item_buttons">
          <Button 
            loading={claimLoading}
            disabled={earnedNumber === 0}
            className={classNames([
              "block_button",
              earnedNumber === 0 && "block_button_disable"
            ])}
            onClick={onClaim}
          >Claim</Button>
        </div>
      </div>
      <MFormList>
      {
        formItems
      }
      </MFormList>
    </div>
  )
}

export const StakingsIndexComponent = ({
  networks,
  networkIndex,
  setNetworkIndex,
  durations,
  durationIndex,
  setDurationIndex,
  networkError,
  loading,
  tokenPrice,
  tokenBalance,
  value,
  setValue,
  onLock,
  onExchange,
  formItems
}) => {
  const { classNames } = useClass()
  const [isDanger, setIsDanger] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)

  useEffect(() => {
    const _isDisabled = (!value || isDanger) && !networkError
    setIsDisabled(_isDisabled)
  }, [value, networkError, isDanger])

  return (
    <div className="m_form m_form_bridge m_form_stakings">
      <div className="form_head">
        <p>Increase your TSP</p>
      </div>
      {
        !!networks && networks.length > 0 && <div className="type_container">
          <p className="type_title">Network</p>
          <div className="type_list">
          {
            networks.map((item, index) => {
              return <MButton 
                label={item.chainFullName} 
                key={index} 
                isSelected={index === networkIndex}
                onClick={() => setNetworkIndex(index)}
              />
            })
          }
          </div>
        </div>
      }
      <MStakingsFormInput 
        tokenPrice={tokenPrice}
        tokenBalance={tokenBalance}
        value={value}
        setValue={setValue}
        isDanger={isDanger}
        setIsDanger={setIsDanger}
      />
      <div className="type_container">
        <p className="type_title">Add Duration</p>
        <div className="type_list">
        {
          durations.map((item, index) => {
            return <MButton 
              label={item.label} 
              key={index} 
              isFlex
              isSelected={index === durationIndex}
              onClick={() => setDurationIndex(index)}
            />
          })
        }
        </div>
      </div>
      <MFormList>
      {
        formItems
      }
      </MFormList>
      <div className="form_button_container">
        <Button
          disabled={isDisabled}
          loading={loading}
          className={classNames([
            "form_button",
            isDisabled && "form_button_disable"
          ])}
          onClick={() => {
            if (networkError) {
              onExchange()
            } else {
              onLock()
            }
          }}
        >{ networkError ? "Switch Network" : "Lock" }</Button>
      </div>
    </div>
  )
}