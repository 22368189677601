/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react"
import { Input } from "antd"
import { mathematics, regex } from "@/util"
import { useClass } from "@/hook"
import { useSessionStore } from "@/store"
import { IMAGE } from "@/assets"
import "./styles.less"

const IndexComponent = ({ 
  token, 
  icon, 
  tokenPrice, 
  tokenBalance, 
  value, 
  setValue,
}) => {
  const { classNames } = useClass()
  const _balance = mathematics.floor(tokenBalance)
  const _balanceLocale = mathematics.localeString(tokenBalance)

  return (
    <div className="m_form_input">
      <div className="input_container">
        <Input 
          block="true" 
          placeholder="0"
          className="input"
          value={value}
          onChange={(e) => {
            const _value = e.target.value
            if (regex.isNumber(_value)) setValue(_value)
          }}
        />
        <img src={icon || IMAGE.default_token} alt="" draggable="false" className="input_icon" />
        <p className="input_label">{ token }</p>
      </div>
      <div className="other_container">
        <p className="amount_usd">
        {`${
            value !== "" && !!tokenPrice ? 
              `$${mathematics.localeString(Number(value) * Number(tokenPrice))}` 
            : 
              "~"
        }`}
        </p>
        <div className="max-button" onClick={() => setValue(_balance)}>
          <p className={classNames([
              "amount",
              !!value && Number(value) > _balance && "danger_amount"
            ])}>{ _balanceLocale}</p>
          <p className="label">Max</p>
        </div>
      </div>
      <img src={IMAGE.add} alt="" draggable="false" className="input_add" />
    </div>
  )
}

export default IndexComponent


export const BridgeIndexComponent = ({ 
  option, 
  balance,
  value, 
  setValue, 
  addressValue, 
  setAddressValue, 
  isFrom, 
  onExchange,
  onSelectNetwork,
  isDanger,
  setIsDanger
}) => {
  const { sState } = useSessionStore()
  const { sMetaMaskAddress } = sState
  const { classNames } = useClass()
  const _balance = mathematics.floor(balance)
  const _balanceLocale = mathematics.localeString(balance)

  useEffect(() => {
    const _isDanger = !!value && Number(value) > _balance
    !!setIsDanger && setIsDanger(_isDanger)
  }, [_balance, value])

  return (
    <div className="m_form_input">
      <div className="other_container">
        <div className="network_div" onClick={() => onSelectNetwork(isFrom)}>
          <img src={option?.chainIcon || IMAGE.default_chain} alt="" draggable="false" className="icon" />
          <p>{`${isFrom ? "From" : "To"}`} <span>{ option?.chainFullName || "~" }</span></p>
          {
            !!option && <img src={IMAGE.arrow} alt="" draggable="false" className="arrow"  />
          }
        </div>
        {
          isFrom && <div className="max-button" onClick={() => setValue(_balance)}>
            <p className={classNames([
              "amount",
              isDanger && "danger_amount"
            ])}>{ _balanceLocale }</p>
            <p className="label">Max</p>
          </div>
        }
      </div>
      <div className="input_container">
        {
          isFrom ?
            <Input 
              block="true" 
              placeholder="0"
              className="input"
              value={value}
              onChange={(e) => {
                const _value = e.target.value
                if (regex.isNumber(_value)) setValue(_value)
              }}
            />
          :
            <p className="input_result">{value || 0}</p>
        }
        <img src={IMAGE.tsp} alt="" draggable="false" className="input_icon" />
        <p className="input_label">TSP</p>
      </div>
      {
        !isFrom && <div className="address_input_container">
          <Input 
            block="true" 
            className="address_input"
            placeholder="Destination Address"
            value={addressValue}
            onChange={(e) => setAddressValue(e.target.value)}
          />
          <img src={IMAGE.wallet} alt="" draggable="false" className="fill_button" onClick={() => setAddressValue(sMetaMaskAddress || "")} />
        </div>
      }
      <img 
        src={IMAGE.exchange} 
        alt="" 
        draggable="false" 
        className="input_add exchange" 
        onClick={onExchange}
      />
    </div>
  )
}

export const StakingsIndexComponent = ({ 
  tokenPrice, 
  tokenBalance, 
  value, 
  setValue,
  isDanger,
  setIsDanger
}) => {
  const { classNames } = useClass()
  const _balance = mathematics.floor(tokenBalance)
  const _balanceLocale = mathematics.localeString(tokenBalance)

  useEffect(() => {
    const _isDanger = !!value && Number(value) > _balance
    !!setIsDanger && setIsDanger(_isDanger)
  }, [_balance, value])

  return (
    <div className="m_form_input m_stakings_form_input">
      <div className="other_container">
        <p className="input_label">Add Stake</p>
      </div>
      <div className="input_container">
        <Input 
          block="true" 
          placeholder="0"
          className="input"
          value={value}
          onChange={(e) => {
            const _value = e.target.value
            if (regex.isNumber(_value)) setValue(_value)
          }}
        />
        <img src={IMAGE.tsp} alt="" draggable="false" className="input_icon" />
        <p className="input_label">TSP</p>
      </div>
      <div className="other_container">
        <p className="amount_usd">
        {`${
            value !== "" && !!tokenPrice ? 
              `$${mathematics.localeString(Number(value) * Number(tokenPrice))}` 
            : 
              "~"
        }`}
        </p>
        <div className="max-button" onClick={() => setValue(tokenBalance)}>
          <p className={classNames([
            "amount",
            isDanger && "danger_amount"
          ])}>{ _balanceLocale }</p>
          <p className="label">Max</p>
        </div>
      </div>
    </div>
  )
}