import { MContainer } from "@/component"
import { useConstant } from "@/hook"
import { IMAGE } from "@/assets"
import "./styles.less"

const IndexComponent = () => {
  const { CONTACTS, DOCUMENTS } = useConstant()

  return (
    <MContainer 
      render={() => (
        <div className="m_foot">
          <div className="m_foot_tool">
            <img src={IMAGE.logo2} alt="" draggable="false" className="logo" />
            <div className="labels">
            {
              DOCUMENTS.map((item, index) => {
                return <p key={index} onClick={() => {
                  window.open(item.link)
                }}>{item.label}</p>
              })
            }
            </div>
            <div className="icons">
            {
              CONTACTS.map((item, index) => {
                return <img src={item.icon} alt="" draggable="false" key={index} onClick={() => window.open(item.link)} />
              })
            }
            </div>
          </div>
          <div className="m_foot_content">
            <p>The Telegram Service Platform (TSP) elevates the Telegram experience by integrating advanced bots into group interactions, all underpinned by our pioneering blockchain technology. This synergy not only enhances communication but also enables the creation and circulation of custom tokens within groups, fostering unique micro-economies. TSP is redefining social engagement on Telegram, offering new dimensions of interaction and economic activity.</p>
            <p>© 2024 TSP. All rights reserved.</p>
          </div>
        </div>
      )}
    />
  )
}
export default IndexComponent
