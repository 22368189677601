const logo =                        "https://telegramserviceplatform.s3.us-west-1.amazonaws.com/web/assets/logo.png"
const logo2 =                       "https://telegramserviceplatform.s3.us-west-1.amazonaws.com/web/assets/logo2.png"
const github =                      "https://telegramserviceplatform.s3.us-west-1.amazonaws.com/web/assets/github.png"
const twitter =                     "https://telegramserviceplatform.s3.us-west-1.amazonaws.com/web/assets/twitter.png"
const telegram =                    "https://telegramserviceplatform.s3.us-west-1.amazonaws.com/web/assets/telegram.png"
const telegram2 =                   "https://telegramserviceplatform.s3.us-west-1.amazonaws.com/web/assets/telegram2.png"
const email =                       "https://telegramserviceplatform.s3.us-west-1.amazonaws.com/web/assets/email.png"
const tabbar_home =                 "https://telegramserviceplatform.s3.us-west-1.amazonaws.com/web/assets/tabbar_home.png"
const tabbar_home2 =                "https://telegramserviceplatform.s3.us-west-1.amazonaws.com/web/assets/tabbar_home2.png"
const tabbar_bridge =               "https://telegramserviceplatform.s3.us-west-1.amazonaws.com/web/assets/tabbar_bridge.png"
const tabbar_bridge2 =              "https://telegramserviceplatform.s3.us-west-1.amazonaws.com/web/assets/tabbar_bridge2.png"
const tabbar_pools =                "https://telegramserviceplatform.s3.us-west-1.amazonaws.com/web/assets/tabbar_pools.png"
const tabbar_pools2 =               "https://telegramserviceplatform.s3.us-west-1.amazonaws.com/web/assets/tabbar_pools2.png"
const tabbar_farms =                "https://telegramserviceplatform.s3.us-west-1.amazonaws.com/web/assets/tabbar_farms.png"
const tabbar_farms2 =               "https://telegramserviceplatform.s3.us-west-1.amazonaws.com/web/assets/tabbar_farms2.png"
const tabbar_stakings =             "https://telegramserviceplatform.s3.us-west-1.amazonaws.com/web/assets/tabbar_stakings.png"
const tabbar_stakings2 =            "https://telegramserviceplatform.s3.us-west-1.amazonaws.com/web/assets/tabbar_stakings2.png"
const arrow =                       "https://telegramserviceplatform.s3.us-west-1.amazonaws.com/web/assets/arrow.png"
const arrow2 =                      "https://telegramserviceplatform.s3.us-west-1.amazonaws.com/web/assets/arrow2.png"
const arrow3 =                      "https://telegramserviceplatform.s3.us-west-1.amazonaws.com/web/assets/arrow3.png"
const sort =                        "https://telegramserviceplatform.s3.us-west-1.amazonaws.com/web/assets/sort.png"
const arbitrum =                    "https://telegramserviceplatform.s3.us-west-1.amazonaws.com/web/assets/arbitrum.png"
const arbitrum2 =                   "https://telegramserviceplatform.s3.us-west-1.amazonaws.com/web/assets/arbitrum2.png"
const bnb =                         "https://telegramserviceplatform.s3.us-west-1.amazonaws.com/web/assets/bnb.png"
const bnb2 =                        "https://telegramserviceplatform.s3.us-west-1.amazonaws.com/web/assets/bnb2.png"
const tick =                        "https://telegramserviceplatform.s3.us-west-1.amazonaws.com/web/assets/tick.png"
const tick2 =                       "https://telegramserviceplatform.s3.us-west-1.amazonaws.com/web/assets/tick2.png"
const tick3 =                       "https://telegramserviceplatform.s3.us-west-1.amazonaws.com/web/assets/tick3.png"
const add =                         "https://telegramserviceplatform.s3.us-west-1.amazonaws.com/web/assets/add.png"
const add2 =                        "https://telegramserviceplatform.s3.us-west-1.amazonaws.com/web/assets/add2.png"
const remove =                      "https://telegramserviceplatform.s3.us-west-1.amazonaws.com/web/assets/remove.png"
const close =                       "https://telegramserviceplatform.s3.us-west-1.amazonaws.com/web/assets/close.png"
const close2 =                      "https://telegramserviceplatform.s3.us-west-1.amazonaws.com/web/assets/close2.png"
const search =                      "https://telegramserviceplatform.s3.us-west-1.amazonaws.com/web/assets/search.png"
const default_chain =               "https://telegramserviceplatform.s3.us-west-1.amazonaws.com/web/assets/default_chain.png"
const default_token =               "https://telegramserviceplatform.s3.us-west-1.amazonaws.com/web/assets/default_token.png"
const meta_mask =                   "https://telegramserviceplatform.s3.us-west-1.amazonaws.com/web/assets/meta-mask.png"
const wallet_connect =              "https://telegramserviceplatform.s3.us-west-1.amazonaws.com/web/assets/wallet-connect.png"
const slide =                       "https://telegramserviceplatform.s3.us-west-1.amazonaws.com/web/assets/slide.png"
const tsp =                         "https://telegramserviceplatform.s3.us-west-1.amazonaws.com/web/assets/tsp.png"
const exchange =                    "https://telegramserviceplatform.s3.us-west-1.amazonaws.com/web/assets/exchange.png"
const copy =                        "https://telegramserviceplatform.s3.us-west-1.amazonaws.com/web/assets/copy.png"
const logout =                      "https://telegramserviceplatform.s3.us-west-1.amazonaws.com/web/assets/logout.png"
const stakings_image =              "https://telegramserviceplatform.s3.us-west-1.amazonaws.com/web/assets/stakings_image.png"
const stakings_icon1 =              "https://telegramserviceplatform.s3.us-west-1.amazonaws.com/web/assets/stakings_icon1.png"
const stakings_icon2 =              "https://telegramserviceplatform.s3.us-west-1.amazonaws.com/web/assets/stakings_icon2.png"
const stakings_icon3 =              "https://telegramserviceplatform.s3.us-west-1.amazonaws.com/web/assets/stakings_icon3.png"
const stakings_icon4 =              "https://telegramserviceplatform.s3.us-west-1.amazonaws.com/web/assets/stakings_icon4.png"
const error =                       "https://telegramserviceplatform.s3.us-west-1.amazonaws.com/web/assets/error.png"
const wallet =                      "https://telegramserviceplatform.s3.us-west-1.amazonaws.com/web/assets/wallet.png"
const video_placehorder =           "https://telegramserviceplatform.s3.us-west-1.amazonaws.com/web/assets/video.png"
const video =                       "https://telegramserviceplatform.s3.us-west-1.amazonaws.com/web/video2.mp4"

const IMAGE = {
  logo,
  logo2,
  github,
  twitter,
  telegram,
  telegram2,
  email,
  tabbar_home,
  tabbar_home2,
  tabbar_bridge,
  tabbar_bridge2,
  tabbar_pools,
  tabbar_pools2,
  tabbar_farms,
  tabbar_farms2,
  tabbar_stakings,
  tabbar_stakings2,
  arrow,
  arrow2,
  arrow3,
  sort,
  arbitrum,
  arbitrum2,
  bnb,
  bnb2,
  tick,
  tick2,
  tick3,
  add,
  add2,
  remove,
  close,
  close2,
  search,
  default_chain,
  default_token,
  meta_mask,
  wallet_connect,
  slide,
  tsp,
  exchange,
  copy,
  logout,
  stakings_image,
  stakings_icon1,
  stakings_icon2,
  stakings_icon3,
  stakings_icon4,
  error,
  wallet,
  video,
  video_placehorder,
}

export default IMAGE