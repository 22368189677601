import { Popover } from "antd"
import { IMAGE } from "@/assets"
import "./styles.less"

const IndexComponent = ({ 
  child, 
  options, 
  sortIndex, 
  setSortIndex, 
  open, 
  setOpen 
}) => {

  return (
    <Popover 
      placement="bottomRight" 
      trigger="click"
      open={open}
      onOpenChange={setOpen}
      content={() => {
        return <div className="m_popover">
        {
          options.map((option, index) => {
            return <div key={index} className="m_popover_item" onClick={() => {
              setSortIndex(index)
              setOpen(false)
            }}>
              <p>{ option.label }</p>
              {
                sortIndex === index && <img src={IMAGE.tick} alt="" draggable="false" />
              }
            </div>
          })
        }
        </div>
      }}
    >
      <>
      {
        child
      }
      </>
    </Popover>
  )
}

export default IndexComponent