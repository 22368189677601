const exchange = async (chainId, rpcUrl) => {
  try {
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: chainId }],
    })
    return true
  } catch (switchError) {
    // 如果用户钱包中没有该链，尝试添加该链
    if (switchError.code === 4902) {
      try {
        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [{
            chainId: chainId,
            rpcUrl: rpcUrl,
          }],
        })
        return true
      } catch (addError) {
        console.error("添加链失败:", addError)
        return false
      }
    } else {
      console.error("切换链失败:", switchError)
      return false
    }
  }
}

export default exchange
