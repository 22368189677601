import { useState } from "react"
import { message } from "antd"
import { web3Get, web3Post, web3Bridge } from "@/service/web3"
import { useSessionStore } from "@/store"

const useWeb3Request = () => {
  const { sState } = useSessionStore()
  const { sMetaMaskAddress } = sState
  const [loading, setLoading] = useState(false)

  const getWeb3Request = async (contractAddress, contractABI, method, args, showError=true) => {
    if (!sMetaMaskAddress) return null
    setLoading(true)
    const result = await web3Get(contractAddress, contractABI, method, args)
    setLoading(false)
    const { success, response } = result
    if (success) {
      console.log(`GET 调用 ${method} 成功：`, response)
      return response
    } else {
      console.error(`GET 调用 ${method} 失败：`, response)
      let _response
      try {
        _response = JSON.parse(response)
      } catch (error) {
        _response = response
      }
      if (showError) message.error(_response.message)
      return null
    }
  }

  const getWeb3RequestNotError = async (contractAddress, contractABI, method, args) => {
    return getWeb3Request(contractAddress, contractABI, method, args, false)
  }

  const postWeb3Request = async (contractAddress, contractABI, method, args, showError=true) => {
    if (!sMetaMaskAddress) return null
    setLoading(true)
    const result = await web3Post(contractAddress, contractABI, method, args)
    setLoading(false)
    const { success, response } = result
    if (success) {
      console.log(`POST 调用 ${method} 成功：`, response)
      return response
    } else {
      console.error(`POST 调用 ${method} 失败：`, response)
      let _response
      try {
        _response = JSON.parse(response)
      } catch (error) {
        _response = response
      }
      if (showError && _response.code !== 4001 && _response.code !== "ACTION_REJECTED") message.error(_response.message)
      return null
    }
  }

  const postWeb3RequestNotError = async (contractAddress, contractABI, method, args) => {
    return postWeb3Request(contractAddress, contractABI, method, args, false)
  }

  const bridgeWeb3Request = async ({contractAddress, contractABI, method, params}) => {
    if (!sMetaMaskAddress) return null
    setLoading(true)
    const result = await web3Bridge({contractAddress, contractABI, method, params})
    setLoading(false)
    const { success, response } = result
    if (success) {
      console.log(`BRIDGE 调用 ${method} 成功：`, response)
      return response
    } else {
      console.error(`BRIDGE 调用 ${method} 失败：`, response)
      let _response
      try {
        _response = JSON.parse(response)
      } catch (error) {
        _response = response
      }
      if (_response.code !== 4001 && _response.code !== "ACTION_REJECTED") message.error(_response.message) 
      return null
    }
  }

  return {
    loading,
    getWeb3Request,
    getWeb3RequestNotError,
    postWeb3Request,
    postWeb3RequestNotError,
    bridgeWeb3Request
  }
}

export default useWeb3Request