import { Spin } from "antd"
import { useClass } from "@/hook"
import { IMAGE } from "@/assets"
import "./styles.less"

const IndexComponent = ({ loading, options, data, minWidth, onRowClick }) => {
  const { classNames } = useClass()

  return (
    loading ? 
      <div className="m_tbl m_tbl_loading">
        <Spin />
      </div>
    :
      <div className="m_tbl">      
        <div className="m_table">
          <div className="m_table_head" style={{ minWidth: !!minWidth ? `${minWidth}px` : "" }}>
          {
            options.map((option, index) => {
              return <div key={index} className={classNames([
                "label_name"
              ])} style={{
                textAlign: !!option.align ? option.align : "left",
                width: !!option.width ? option.width : "auto",
                flex: !!option.width ? "none" : !!option.flex ? option.flex : 1,
              }}>{ option.title }</div>
            })
          }
          </div>
          {
            data.length > 0 ?
              <div className="m_table_list" style={{ minWidth: !!minWidth ? `${minWidth}px` : "" }}>
              {
                data.map((item, index) => {
                  return <div key={index} className="list_item" onClick={() => {
                    !!onRowClick && onRowClick(item)
                  }}>
                    {
                      options.map((option, idx) => {
                        return <div key={idx} className={classNames([
                          "item_container",
                          !!option.className && option.className
                        ])} style={{
                          justifyContent: option.align === "right" ? "flex-end" : "flex-start",
                          width: !!option.width ? option.width : "auto",
                          flex: !!option.width ? "none" : !!option.flex ? option.flex : 1,
                        }}>
                        {
                          !!option.render ? 
                            option.render(item[option.dataIndex], item) 
                          : !!option.format ?
                            option.format(item[option.dataIndex], item)
                          : 
                            item[option.dataIndex]
                        }
                        </div>
                      })
                    }
                  </div>
                })
              }
              </div>
            :
              <p className="m_table_noData">No Data</p>
          }
          
        </div>
        <img src={IMAGE.slide} alt="" draggable="false" className="m_tbl_slide" />
      </div>
  )
}

export default IndexComponent