
const regex = {

  isEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  },

  isNumber(number) {
    const numberRegex = /^(?:[0-9]+(?:\.[0-9]*)?|\.[0-9]+)?$/
    return numberRegex.test(number)
  }

}

export default regex