import { Segmented } from "antd"
import "./styles.less"

const IndexComponent = ({ options, value, onChange, buttons }) => {

  return (
    <div className="m_table_tool">
      <Segmented 
        options={options} 
        value={value} 
        onChange={onChange} 
        className="m_table_tool_segmented"
      />
      {/* {
        !!buttons && <div className="m_table_tool_buttons">
        {
          buttons
        }
        </div>
      } */}
    </div>
  )
}

export default IndexComponent