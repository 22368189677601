import { useRequest } from "@/hook"
import { useLocalStore } from "@/store"

const useInfo = () => {
  const { request } = useRequest()
  const { lDispatch } = useLocalStore()

  const initNetworkOptions = async () => {
    const resp = await request(`pool/get_all_bridges?isTestnet=${process.env.REACT_APP_DEBUG}`)
    if (resp === null) return
    lDispatch({key: "lNetworkOptions", value: resp})
  }

  return {
    initNetworkOptions
  }
}

export default useInfo