/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react"
import { useSessionStore } from "@/store"
import { storage } from "@/util"
import { 
  connect,
  exchange,
  ETHERS
} from "@/ethers"

const useEthers = () => {
  const { sDispatch } = useSessionStore()

  const connectWallet = async () => {
    const resp = await connect()
    if (!resp) return
    const { signer, provider } = resp
    const network = await provider.getNetwork()
    const address = await signer.getAddress()
    const chainId = network.chainId
    sDispatch({key: "sMetaMaskAddress", value: address})
    sDispatch({key: "sChainId", value: chainId})
  }

  const disconnectWallet = () => {
    storage.clears()
    storage.clearl()
    setTimeout(() => {
      window.location.reload()
    }, 500)
  }

  const exchangeNetwork = async (_) => {
    if (typeof window.ethereum === 'undefined') return
    const resp = await exchange(_)
    return resp
  }

  useEffect(() => {
    if (storage.getl('cachedProvider')) {
      connectWallet()
    }
  }, [])

  useEffect(() => {
    const handleAccountsChanged = (accounts) => {
      if (accounts.length === 0) {
        disconnectWallet()
      } else {
        const address = accounts[0]
        sDispatch({key: "sMetaMaskAddress", value: address})
        setTimeout(() => {
          window.location.reload()
        }, 500)
      }
    }

    const handleChainChanged = (_) => {
      const chainId = ETHERS.parse(_)
      sDispatch({key: "sChainId", value: chainId})
    }

    if (typeof window.ethereum === "undefined") return
    window.ethereum.on("accountsChanged", handleAccountsChanged)
    window.ethereum.on("chainChanged", handleChainChanged)

    return () => {
      window.ethereum.removeListener("accountsChanged", handleAccountsChanged)
      window.ethereum.removeListener("chainChanged", handleChainChanged)
    }
  }, [])

  return {
    connectWallet,
    disconnectWallet,
    exchangeNetwork
  }
}

export default useEthers