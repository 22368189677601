/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react"
import { withRouter } from "react-router-dom"
import { message } from "antd"
import { MContainer, MPool1Form } from "@/component"
import { useWeb3Request, useRoute } from "@/hook"
import { ETHERS } from "@/ethers"
import { useSessionStore } from "@/store"
import moment from "moment"
import { ABI } from "@/assets"
import "./styles.less"

const IndexPage = ({ history }) => {
  const DATA = history.location.state
  const { sState } = useSessionStore()
  const { withStatePush } = useRoute()
  const { getWeb3RequestNotError, postWeb3Request } = useWeb3Request()
  const { sMetaMaskAddress } = sState
  const [reload, setReload] = useState(false)
  const [stakedNumber, setStakedNumber] = useState(0)
  const [profit, setProfit] = useState(0)
  const [estDailyYield, setEstDailyYield] = useState(0)
  const [totalStakeValue, setTotalStakeValue] = useState(0)
  const [poolTVL, setPoolTVL] = useState(0)
  const [shareOfPool, setShareOfPool] = useState(0)
  const [removeLoading, setRemoveLoading] = useState(false)
  const [claimLoading, setClaimLoading] = useState(false)

  const getAddressArg = () => {
    const contractAddresses = DATA.tokens.map(token => token.contractAddress)
    while (contractAddresses.length < 5) {
      contractAddresses.push("0x0000000000000000000000000000000000000000")
    }
    return contractAddresses
  }

  useEffect(() => {
    const getStakedNumber = async () => {
      const addressArg = getAddressArg()
      console.log("%cPOOL => getUserStakeTokenValue，调用的合约地址：", "color: red; font-weight: bold;", DATA.contractAddress)
      console.log("%cPOOL => getUserStakeTokenValue，调用的合约参数：", "color: red; font-weight: bold;", [sMetaMaskAddress, addressArg])
      const resp = await getWeb3RequestNotError(
        DATA.contractAddress, 
        ABI.STAKINGPOOL, 
        "getUserStakeTokenValue", 
        [sMetaMaskAddress, addressArg]
      )
      if (resp === null) return
      setStakedNumber(ETHERS.format(resp))
    }
    getStakedNumber()
  }, [sMetaMaskAddress, reload])

  useEffect(() => {
    const getProfit = async () => {
      const addressArg = getAddressArg()
      const time = moment().unix()
      console.log("%cPOOL => getProfit，调用的合约地址：", "color: red; font-weight: bold;", DATA.contractAddress)
      console.log("%cPOOL => getProfit，调用的合约参数：", "color: red; font-weight: bold;", [sMetaMaskAddress, addressArg, time])
      const resp = await getWeb3RequestNotError(
        DATA.contractAddress, 
        ABI.STAKINGPOOL, 
        "getProfit", 
        [sMetaMaskAddress, addressArg, time]
      )
      if (resp === null) return
      setProfit(ETHERS.format(resp))
    }
    getProfit()
  }, [sMetaMaskAddress, reload])

  useEffect(() => {
    const getTotalStakeValue = async () => {
      const resp = await getWeb3RequestNotError(
        DATA.contractAddress, 
        ABI.STAKINGPOOL, 
        "totalStakeValue", 
        []
      )
      if (resp === null) return
      setTotalStakeValue(ETHERS.format(resp))
    }
    getTotalStakeValue()
  }, [reload])

  const onClaim = async () => {
    setClaimLoading(true)
    const resp = await postWeb3Request(
      DATA.contractAddress, 
      ABI.STAKINGPOOL, 
      "settlement", 
      [getAddressArg()]
    )
    setClaimLoading(false)
    if (resp === null) return
    setReload(!reload)
    message.success("Claim successfully")
  }

  const onRemove = async () => {
    setRemoveLoading(true)
    const addressArg = getAddressArg()
    const resp = await postWeb3Request(
      DATA.contractAddress, 
      ABI.STAKINGPOOL, 
      "withdraw", 
      [addressArg]
    )
    setRemoveLoading(false)
    if (resp === null) return
    setReload(!reload)
    message.success("Remove successfully")
  }

  useEffect(() => {
    const getPoolTVL = async () => {
      const addressArg = getAddressArg()
      const resp = await getWeb3RequestNotError(
        DATA.contractAddress, 
        ABI.STAKINGPOOL, 
        "getPoolTVL", 
        [addressArg]
      )
      if (resp === null) return
      setPoolTVL(ETHERS.format(resp))
    }
    getPoolTVL()
  }, [reload])

  useEffect(() => {
    if (totalStakeValue === 0) return
    const _estDailyYield = (stakedNumber / totalStakeValue) * 690000
    setEstDailyYield(_estDailyYield)
    const _shareOfPool = poolTVL === 0 ? 1 : stakedNumber / poolTVL
    setShareOfPool(_shareOfPool)
  }, [stakedNumber, totalStakeValue, poolTVL])
  
  return (
    <div className="p_pool1">
      <MContainer
        render={() => (
          <MPool1Form 
            name="Pool"
            tokens={DATA.tokens}
            chainFullName={DATA.chainFullName}
            tvl={DATA.tvl}
            multiplier={DATA.multiplier}
            apr={DATA.apr}
            stakedNumber={stakedNumber}
            earnedNumber={profit}
            estDailyYield={estDailyYield}
            shareOfPool={shareOfPool}
            removeLoading={removeLoading}
            claimLoading={claimLoading}
            onAdd={() => withStatePush("/pool2", DATA)}
            onRemove={onRemove}
            onClaim={onClaim}
          />
        )}
      />
    </div>
  )
}
export default withRouter(IndexPage)

/**
   * staked: 已有的Token数量（调用合约 getUserStakedTokenAmountFromPool 方法） + 将要质押的token数量
   * earned：当前未结算收益 (调用合约getProfit方法)，单位TSP
   * Est. Daily Yield: (已质押币种价值(getUserStakeTokenValue)) / (totalStakeValue ) x 69万
   * Share of Pool: 已质押币种价值(getUserStakeTokenValue) / getPoolTVL
**/ 