/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react"
import { withRouter } from "react-router-dom"
import { message, Spin } from "antd"
import moment from "moment"
import { MContainer, MStakingsItem, MStakingsForm, MFormItem, MFormItemValue } from "@/component"
import { useRequest, useEthers, useWeb3Request, useConstant } from "@/hook"
import { useSessionStore } from "@/store"
import { mathematics, theString } from "@/util"
import { ETHERS } from "@/ethers"
import { IMAGE, ABI } from "@/assets"
import "./styles.less"

const IndexPage = () => {
  const { sState } = useSessionStore()
  const { exchangeNetwork } = useEthers()
  const { DURATIONS } = useConstant()
  const { request } = useRequest()
  const { getWeb3RequestNotError, postWeb3Request } = useWeb3Request()
  const { sMetaMaskAddress, sChainId } = sState
  const [networks, setNetworks] = useState([])
  const [value, setValue] = useState("")
  const [networkError, setNetworkError] = useState(false)
  const [networkIndex, setNetworkIndex] = useState(0)
  const [durationIndex, setDurationIndex] = useState(2)
  const [tspBalance, setTspBalance] = useState("")
  const [tspUSD, setTspUSD] = useState(0) 
  const [lockLoading, setLockLoading] = useState(false)
  const [orderLoading, setOrderLoading] = useState(false)
  const [orders, setOrders] = useState(null)
  const [reload, setReload] = useState(false)
  const [multiplier, setMultiplier] = useState(1)
  const [estDailyYield, setEstDailyYield] = useState(0)
  const [apr, setApr] = useState(0)
  const [lockuntil, setLockuntil] = useState("")
  const [totalStakeTSPAmount, setTotalStakeTSPAmount] = useState(0)
  const [totalEarnedTSPAmount, setTotalEarnedTSPAmount] = useState(0)
  const [withdrawLoading, setWithdrawLoading] = useState(-1)

  useEffect(() => {
    const getNetwork = async () => {
      const resp = await request(`pool/all_fix_deposit?isTestnet=${process.env.REACT_APP_DEBUG}`)
      if (resp === null) return
      setNetworks(resp)
    }
    getNetwork()
  }, [])

  useEffect(() => {
    if (!networks || networks.length === 0 || !sChainId) return
    const _networkIndex = networks.findIndex(obj => Number(obj.chainId) === sChainId)
    if (_networkIndex === -1) {
      setNetworkError(true)
    } else {
      setNetworkError(false)
      setNetworkIndex(_networkIndex)
    }
  }, [networks, sChainId])

  useEffect(() => {
    const getTotalStakeTSPAmount = async () => {
      const _network = networks[networkIndex]
      const resp = await getWeb3RequestNotError(
        _network.contractAddress, 
        ABI.STAKINGTSP, 
        "getUserLockedAmount", 
        [sMetaMaskAddress]
      )
      const _totalStakeTSPAmount = resp === null ? 0 : ETHERS.format(resp)
      setTotalStakeTSPAmount(_totalStakeTSPAmount)
    }
    networks.length > 0 && !networkError && getTotalStakeTSPAmount()
  }, [sMetaMaskAddress, networks, networkIndex, networkError, reload])

  useEffect(() => {
    if (!orders) return
    const _totalEarnedTSPAmount = orders.reduce(function (accumulator, currentObject) {
      return accumulator + ETHERS.format(currentObject.earned)
    }, 0)
    setTotalEarnedTSPAmount(_totalEarnedTSPAmount)
  }, [orders])

  useEffect(() => {
    const getTspData = async () => {
      const _network = networks[networkIndex]
      const tspAddress = await getWeb3RequestNotError(
        _network.contractAddress, 
        ABI.STAKINGTSP, 
        "tspTokenAddress", 
        []
      )
      if (tspAddress === null) return
      const balanceResp = await getWeb3RequestNotError(
        tspAddress, 
        ABI.ERC20, 
        "balanceOf", 
        [sMetaMaskAddress]
      )
      const balance = balanceResp === null ? 0 : ETHERS.format(balanceResp)
      setTspBalance(balance)
      const priceResp = await getWeb3RequestNotError(
        _network.contractAddress, 
        ABI.STAKINGTSP, 
        "getTokenPrice", 
        [tspAddress]
      )
      if (priceResp === null) return
      setTspUSD(ETHERS.format(priceResp))
    }
    networks.length > 0 && !networkError && getTspData()
  }, [sMetaMaskAddress, networks, networkIndex, networkError, reload])

  useEffect(() => {
    const getOrders = async () => {
      setOrderLoading(true)
      const _network = networks[networkIndex]
      const _length = await getWeb3RequestNotError(
        _network.contractAddress, 
        ABI.STAKINGTSP, 
        "getUserOrderLength", 
        [sMetaMaskAddress]
      )
      if (_length === null) {
        setOrderLoading(false)
        setOrders([])
        return
      }
      const length = ETHERS.parse(_length)
      if (length === 0) {
        setOrderLoading(false)
        setOrders([])
        return
      }
      const promises = Array.from({ length: length }, async(_, i) => {
        const resp = await getWeb3RequestNotError(
          _network.contractAddress, 
          ABI.STAKINGTSP, 
          "userDepositOrder", 
          [sMetaMaskAddress, i]
        )
        console.log("%cSTAKING => getOrderEarned", "color: red; font-weight: bold;", _network.contractAddress)
        console.log("%cSTAKING => getOrderEarned", "color: red; font-weight: bold;", [sMetaMaskAddress, i])
        const earned = await getWeb3RequestNotError(
          _network.contractAddress, 
          ABI.STAKINGTSP, 
          "getOrderEarned", 
          [sMetaMaskAddress, i]
        )
        return {
          ...resp,
          earned: earned
        }
      })
      const results = await Promise.all(promises)
      setOrderLoading(false)
      setOrders(results)
    }
    networks.length > 0 && !networkError && getOrders()
  }, [sMetaMaskAddress, networks, networkIndex, networkError, reload])

  const onLock = async () => {
    if (networks.length === 0 || networkError) return
    const _network = networks[networkIndex]
    setLockLoading(true)
    const tspAddress = await getWeb3RequestNotError(
      _network.contractAddress, 
      ABI.STAKINGTSP, 
      "tspTokenAddress", 
      []
    )
    if (tspAddress === null) {
      setLockLoading(false)
      return
    }
    const approveResp = await postWeb3Request(
      tspAddress, 
      ABI.ERC20, 
      "approve", 
      [_network.contractAddress, ETHERS.bignumber(Math.ceil(value), 18)]
    )
    if (approveResp === null) {
      setLockLoading(false)
      return
    } 
    const day = DURATIONS[durationIndex].value
    const resp = await postWeb3Request(
      _network.contractAddress, 
      ABI.STAKINGTSP, 
      "stake", 
      [day, ETHERS.bignumber(value)]
    )
    setLockLoading(false)
    if (resp === null) return
    setReload(!reload)
    message.success("Lock successfully")
  }

  const onExchange = async () => {
    const _network = networks[networkIndex]
    const chainId = theString.number16String(_network.chainId)
    const rpcUrl = _network.rpcUrl
    await exchangeNetwork(chainId, rpcUrl)
  }

  useEffect(() => {
    const getMultiplier = async () => {
      const _network = networks[networkIndex]
      const _day = DURATIONS[durationIndex].value
      const resp = await getWeb3RequestNotError(
        _network.contractAddress, 
        ABI.STAKINGTSP, 
        "dayWeightMap", 
        [_day]
      )
      const _weight = resp === null ? 1 : ETHERS.number(resp) / 100
      setMultiplier(_weight)
    }
    networks.length > 0 && !networkError && getMultiplier()
  }, [durationIndex, networks, networkIndex, networkError])

  useEffect(() => {
    const getEstDailyYield = async () => {
      const _network = networks[networkIndex]
      const totalStakeResp = await getWeb3RequestNotError(
        _network.contractAddress, 
        ABI.STAKINGTSP, 
        "totalStakeValue", 
        []
      )
      if (totalStakeResp === null) {
        setEstDailyYield(0)
        return
      }
      const _totalStakeValue = ETHERS.format(totalStakeResp)
      if (_totalStakeValue === 0) {
        setEstDailyYield(0)
        return
      }
      const divideResp = await getWeb3RequestNotError(
        _network.contractAddress, 
        ABI.STAKINGTSP, 
        "divideTSPAmount", 
        []
      )
      if (divideResp === null) {
        setEstDailyYield(0)
        return
      }
      const _divideTSPAmount = ETHERS.format(divideResp)
      if (_divideTSPAmount === 0) {
        setEstDailyYield(0)
        return
      }
      const _estDailyYield = (Number(value) * multiplier / _totalStakeValue) * _divideTSPAmount
      setEstDailyYield(_estDailyYield)
    }
    networks.length > 0 && !networkError && !!value && getEstDailyYield()
  }, [networks, networkIndex, networkError, multiplier, value])

  useEffect(() => {
    const getApr = async () => {
      const _apr = (estDailyYield / Number(value)) * 365
      setApr(_apr)
    }
    !!value && estDailyYield !== 0 && getApr()
  }, [estDailyYield, value])

  useEffect(() => {
    const _day = DURATIONS[durationIndex].value
    const _lockuntil = moment().add(_day, "days").format("MMM D, YYYY [at] h:mm A")
    setLockuntil(_lockuntil)
  }, [durationIndex])

  const onWithdraw = async (index) => {
    if (networks.length === 0 || networkError) return
    const _network = networks[networkIndex]
    setWithdrawLoading(index)
    const resp = await postWeb3Request(
      _network.contractAddress, 
      ABI.STAKINGTSP, 
      "withdraw", 
      [index]
    )
    setWithdrawLoading(-1)
    if (resp === null) return
    setReload(!reload)
    message.success("Withdraw successfully")
  }
  
  return (
    <div className="p_stakings">
      <MContainer
        render={() => (
          <div className="p_stakings_head">
            <p className="head_title">Stakings</p>
            <p className="head_desc">Lock your TSP tokens to receive TSP.</p>
          </div>
        )}
      />
      <MContainer
        render={() => (
          <div className="p_stakings_body">
            <div className="details_container">
              <div className="card_container">
                <img src={IMAGE.stakings_image} alt="" draggable="false" className="card_image" />
                <div className="card_desc">
                  <p className="card_desc_name">Total TSP Locked</p>
                  <div className="card_desc_content">
                    <p className="amount">{ mathematics.localeString(totalStakeTSPAmount) }</p>
                  </div>
                </div>
                <div className="card_desc">
                  <p className="card_desc_name">Total TSP Earned</p>
                  <div className="card_desc_content">
                    <p className="amount">{ mathematics.localeString(totalEarnedTSPAmount) }</p>
                  </div>
                </div>
              </div>
              {
                orderLoading && <div className="order_loading">
                  <Spin />
                </div>
              }
              {
                !!orders && orders.map((item, index) => {
                  return <MStakingsItem 
                    key={index}
                    value={ETHERS.format(item.amount)}
                    day={ETHERS.number(item.day)}
                    lockedTime={ETHERS.number(item.depositTime)}
                    claimTime={ETHERS.number(item.claimTime)}
                    status={ETHERS.format(item.status)}
                    earned={ETHERS.format(item.earned)}
                    multiplier={ETHERS.number(item.weight) / 100}
                    loading={withdrawLoading === index}
                    onClick={() => onWithdraw(index)}
                  />
                })
              }
              {
                !orderLoading && !!orders && orders.length === 0 &&  <p className="order_noData">No Data</p>
              }
            </div>
            <MStakingsForm 
              networks={networks}
              networkIndex={networkIndex}
              setNetworkIndex={setNetworkIndex}
              durations={DURATIONS}
              durationIndex={durationIndex}
              setDurationIndex={setDurationIndex}
              networkError={networkError}
              tokenPrice={tspUSD}
              tokenBalance={tspBalance}
              value={value}
              setValue={setValue}
              onLock={onLock}
              onExchange={onExchange}
              loading={lockLoading}
              formItems={[
                <MFormItem 
                  key={0}
                  label="Est. Daily Yield" 
                  valueDom={
                    <MFormItemValue data={[{
                      icon: IMAGE.tsp, 
                      value: `${mathematics.localeString(estDailyYield)} TSP`
                    }]} />
                  } 
                />,
                <MFormItem 
                  key={1}
                  label="Multiplier" 
                  value={`${multiplier}x`} 
                />,
                <MFormItem 
                  key={2}
                  label="APR" 
                  value={`${mathematics.localeString(apr * 100)}%`} 
                />,
                <MFormItem 
                  key={3}
                  label="Unlock Until" 
                  value={lockuntil} 
                />
              ]}
            />
          </div>
        )}
      />
    </div>
  )
}
export default withRouter(IndexPage)

/*
  Multiplier = dayWeightMap[天数] / 100
  Est Daily Reward: (输入的金额 x Multiplier / totalStakeValue) x divideTSPAmount
  APR: （[Est Daily Reward] / 输入的金额） * 365
  lockuntil: 当前日期加上选择的天数
*/ 