
const theString = {

  ellipsisMiddle(str, maxLength) {
    if (!str) return ""
    if (str.length <= maxLength) return str
    const leftPart = str.slice(0, maxLength / 2)
    const rightPart = str.slice(-maxLength / 2)
    return leftPart + '...' + rightPart
  },

  invalid(str) {
    if (str === undefined || str === null) {
      return true
    }
    return false
  },

  standard(str) {
    return !this.invalid(str) ? str : "-"
  },

  colorHexToRgba(hex, alpha = 1) {
    hex = hex.replace("#", "")
    const r = parseInt(hex.substring(0, 2), 16)
    const g = parseInt(hex.substring(2, 4), 16)
    const b = parseInt(hex.substring(4, 6), 16)
    return `rgba(${r}, ${g}, ${b}, ${alpha})`
  },

  number16String(number) {
    const _number = Number(number)
    const __number = _number.toString(16)
    return `0x${__number}`
  }

}

export default theString