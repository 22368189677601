import { IMAGE } from "@/assets"

const useConstant = () => {

  const TABBARHOME = [{
    icon: IMAGE.tabbar_home,
    icon2: IMAGE.tabbar_home2,
    label: "Home",
    route: "/",
    visible: true
  }]

  const TABBARS = [{
    icon: IMAGE.tabbar_bridge,
    icon2: IMAGE.tabbar_bridge2,
    label: "Bridge",
    route: "/bridge",
    tag: "bridge",
    visible: true
  }, {
    icon: IMAGE.tabbar_pools,
    icon2: IMAGE.tabbar_pools2,
    label: "Pools",
    route: "/pools",
    tag: "pool"
  }, {
    icon: IMAGE.tabbar_farms,
    icon2: IMAGE.tabbar_farms2,
    label: "Farms",
    route: "/farms",
    tag: "farm"
  }, {
    icon: IMAGE.tabbar_stakings,
    icon2: IMAGE.tabbar_stakings2,
    label: "Stakings",
    route: "/stakings",
    tag: "stakings"
  }]

  const CONTACTS = [{
    icon: IMAGE.github,
    link: "https://github.com/tspnetwork"
  }, {
    icon: IMAGE.twitter,
    link: "https://twitter.com/TSP_protocol"
  }, {
    icon: IMAGE.telegram,
    link: "https://t.me/TSPProtocol"
  }, {
    icon: IMAGE.telegram2,
    link: "https://t.me/TSP_Automated"
  }, {
    icon: IMAGE.email,
    link: "mailto:contact@tsp.network"
  }]

  const DOCUMENTS = [{
    label: "Documentation",
    link: "https://docs.tsp.network"
  }, {
    label: "Blockchain Browser",
    link: "https://scan.tsp.network"
  }]

  const TOKENS = [{
    name: "All Tokens"
  }, {
    name: "ETH"
  }, {
    name: "TSP"
  }, {
    name: "USDT"
  }, {
    name: "USDC"
  }, {
    name: "UNI"
  }]

  const NETWORKS = [{
    name: "All Networks"
  }, {
    name: "BNB Chain"
  }, {
    name: "Arbitrum"
  }]

  const SORTS = [{
    label: "Hot",
    value: "hot"
  }, {
    label: "Latest",
    value: "latest"
  }, {
    label: "Multiplier",
    value: "multiplier"
  }, {
    label: "Liquidity",
    value: "liquidity"
  }, {
    label: "APR",
    value: "apr"
  }]

  const WALLETS = [{
    name: "MetaMask",
    icon: IMAGE.meta_mask,
    key: "injected",
  }, {
    name: "WalletConnect",
    icon: IMAGE.wallet_connect,
    key: "walletconnect"
  }]

  const DURATIONS = [{
    label: "7D",
    value: 7
  }, {
    label: "30D",
    value: 30
  }, {
    label: "90D",
    value: 90
  }, {
    label: "180D",
    value: 180
  }, {
    label: "360D",
    value: 360
  }]

  return {
    TABBARHOME,
    TABBARS,
    CONTACTS,
    DOCUMENTS,
    TOKENS,
    NETWORKS,
    SORTS,
    WALLETS,
    DURATIONS
  }
}

export default useConstant