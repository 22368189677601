import { useState, useEffect } from "react"
import { withRouter } from "react-router-dom"
import { MContainer, MFoot } from "@/component"
import { theWindow } from "@/util"
import { IMAGE } from "@/assets"
import { openDB } from "idb"
import "./styles.less"

const IndexPage = () => {
  const [videoUrl, setVideoUrl] = useState("")
  const isCantPlayMP4 = theWindow.isCantPlayMP4()

  useEffect(() => {
    const loadVideo = async () => {
      const db = await openDB("VideoCache", 1, {
        upgrade(db) {
          db.createObjectStore("videos")
        },
      })
      const _cachedVideo = await db.get("videos", "VIDEO1")
      if (_cachedVideo) {
        await db.delete("videos", "VIDEO1")
      }
      const cachedVideo = await db.get("videos", "VIDEO2")
      if (cachedVideo) {
        setVideoUrl(URL.createObjectURL(cachedVideo))
      } else {
        const response = await fetch(IMAGE.video)
        const blob = await response.blob()
        await db.put('videos', blob, "VIDEO2")
        setVideoUrl(URL.createObjectURL(blob))
      }
    }
    !isCantPlayMP4 && loadVideo()
  }, [isCantPlayMP4])

  
  return (
    <div className="p_home">
      <MContainer
        render={() => (
          <div className="p_home_container">
            <p className="container_title" dangerouslySetInnerHTML={{__html: `<span>Beyond</span>\nThe Open Network`}} />
            <p className="container_content">A wide range of Telegram Bots, enhancing social experience with blockchain innovation.</p>
            {
              !!videoUrl ?
                <video 
                  src={videoUrl} 
                  loop 
                  autoPlay 
                  muted 
                  playsInline
                  className="content_video"
                />
              :
                <img src={IMAGE.video_placehorder} alt="" draggable="false" className="content_video" />
            }
          </div>
        )}
      />
      <MFoot />
    </div>
  )
}
export default withRouter(IndexPage)