/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react"
import { withRouter } from "react-router-dom"
import { message } from "antd"
import { MContainer, MBridgeForm, MModal } from "@/component"
import { ETHERS } from "@/ethers"
import { useEthers, useWeb3Request, useInfo } from "@/hook"
import { useSessionStore, useLocalStore } from "@/store"
import { theString } from "@/util"
import { IMAGE, ABI } from "@/assets"
import "./styles.less"

const IndexPage = () => {
  const { sState } = useSessionStore()
  const { lState } = useLocalStore()
  const { exchangeNetwork } = useEthers()
  const { sMetaMaskAddress, sChainId } = sState
  const { getWeb3RequestNotError, bridgeWeb3Request } = useWeb3Request()
  const { initNetworkOptions } = useInfo()
  const { lNetworkOptions } = lState
  const [fromOption, setFromOption] = useState(null)
  const [toOption, setToOption] = useState(null)
  const [options, setOptions] = useState([])
  const [fromOptionIndex, setFromOptionIndex] = useState(0)
  const [toOptionIndex, setToOptionIndex] = useState(1)
  const [tspBalance, setTspBalance] = useState("")
  const [amountValue, setAmountValue] = useState("")
  const [addressValue, setAddressValue] = useState("")
  const [loading, setLoading] = useState(false)
  const [networkOptions, setNetworkOptions] = useState([])
  const [networkOpen, setNetworkOpen] = useState(false)
  const [networkIndex, setNetworkIndex] = useState(0)
  const [isFromNetwork, setFromNetwork] = useState(false)

  useEffect(() => {
    initNetworkOptions()
  }, [])

  useEffect(() => {
    if (!!lNetworkOptions) {
      setOptions(lNetworkOptions)
      const _networkOptions = lNetworkOptions.map(option => {
        return { 
          ...option, 
          _label: option.chainFullName,
          _icon: option.chainIcon || IMAGE.default_chain
        }
      })
      setNetworkOptions(_networkOptions)
    }
  }, [lNetworkOptions])

  useEffect(() => {
    if (options.length <= fromOptionIndex) return
    setFromOption(options[fromOptionIndex])
    getTspBalance(options[fromOptionIndex])
  }, [options, fromOptionIndex])

  useEffect(() => {
    if (options.length <= toOptionIndex) return
    setToOption(options[toOptionIndex])
  }, [options, toOptionIndex])

  useEffect(() => {
    if (!sChainId) return
    const _fromIndex = options.findIndex(option => Number(option.chainId) === Number(sChainId))
    if (_fromIndex === -1) return
    const _fromOptionIndex = fromOptionIndex
    setFromOptionIndex(_fromIndex)
    const _toIndex = toOptionIndex === _fromIndex ? _fromOptionIndex :  toOptionIndex
    setToOptionIndex(_toIndex)
  }, [sChainId, options])

  const getTspBalance = async (option) => {
    const resp = await getWeb3RequestNotError(
      option.tspContractAddress, 
      ABI.ERC20, 
      "balanceOf", 
      [sMetaMaskAddress]
    )
    const balance = resp === null ? 0 : ETHERS.format(resp)
    setTspBalance(balance)
  }

  const onExchange = async () => {
    if (!toOption) return
    const chainId = theString.number16String(toOption.chainId)
    const rpcUrl = toOption.rpcUrl
    exchangeNetwork(chainId, rpcUrl)
  }

  const onSelectNetwork = (_) => {
    setFromNetwork(_)
    const _index = !!_ ? fromOptionIndex : toOptionIndex
    setNetworkIndex(_index)
    setNetworkOpen(true)
  }

  const onSetNetworkIndex = async (_) => {
    if (isFromNetwork) {
      const _option = options[_]
      const chainId = theString.number16String(_option.chainId)
      const rpcUrl = _option.rpcUrl
      const resp = await exchangeNetwork(chainId, rpcUrl)
      if (!resp) return
      const _fromOptionIndex = fromOptionIndex
      setFromOptionIndex(_)
      if (toOptionIndex === _) setToOptionIndex(_fromOptionIndex)
    } else {
      setToOptionIndex(_)
    }
  } 

  const onTransfer = async () => {
    setLoading(true)
    const resp = await bridgeWeb3Request({
      contractAddress: fromOption.tspContractAddress,
      contractABI: ABI.TSP,
      method: "sendFrom",
      params: {
        fromAddress: sMetaMaskAddress,
        address: addressValue || sMetaMaskAddress,
        dstChainId: Number(toOption.bridgeId),
        amount: amountValue
      }
    })
    setLoading(false)
    if (resp === null) return
    getTspBalance(options[fromOptionIndex])
    message.success("Transfer successfully")
  }
  
  return (
    <div className="p_bridge">
      <MContainer
        render={() => (
          <MBridgeForm 
            fromOption={fromOption}
            toOption={toOption}
            tspBalance={tspBalance}
            amountValue={amountValue}
            setAmountValue={setAmountValue}
            addressValue={addressValue}
            setAddressValue={setAddressValue}
            onExchange={onExchange}
            onTransfer={onTransfer}
            loading={loading}
            onSelectNetwork={onSelectNetwork}
          />
        )}
      />
      <MModal 
        open={networkOpen}
        setOpen={setNetworkOpen}
        title="Network"
        options={networkOptions}
        selidx={networkIndex}
        setSelidx={onSetNetworkIndex}
      />
    </div>
  )
}
export default withRouter(IndexPage)