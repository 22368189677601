import { IMAGE } from "@/assets"
import "./styles.less"

const IndexComponent = ({ name, icon, selected, onClick }) => {

  return (
    <div className="m_modal_item" onClick={onClick}>
      <img src={icon} alt="" className="m_modal_item_icon" />
      <div className="m_modal_item_content">
        <p>{ name }</p>
        {
          !!selected && <img src={IMAGE.tick2} alt="" />
        }
      </div>
    </div>
  )
}
IndexComponent.displayName = "m_modal_item"

export default IndexComponent
