import IMAGE from "./image"
import TOKENERC20 from "./abi/token-erc20"
import TOKENTSP from "./abi/token-tsp"
import STAKINGPOOL from "./abi/staking-pool"
import STAKINGFARM from "./abi/staking-farm"
import STAKINGTSP from "./abi/staking-tsp"

const ABI = {
  ERC20: TOKENERC20,
  TSP: TOKENTSP,
  STAKINGPOOL: STAKINGPOOL,
  STAKINGFARM: STAKINGFARM,
  STAKINGTSP: STAKINGTSP
}

export { 
  IMAGE,
  ABI 
}