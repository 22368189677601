/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react"
import { withRouter } from "react-router-dom"
import { MContainer, MButton, MTableTool, MTable, MTokens, MChain, MPopover, MModal } from "@/component"
import { useConstant, useRoute, useRequest, useEthers } from "@/hook"
import { useSessionStore } from "@/store"
import { mathematics, theString } from "@/util"
import { IMAGE } from "@/assets"
import "./styles.less"

const IndexPage = () => {
  const { sState } = useSessionStore()
  const { TOKENS, NETWORKS,SORTS } = useConstant()
  const { withStatePush } = useRoute()
  const { request } = useRequest()
  const { exchangeNetwork } = useEthers()
  const { sMetaMaskAddress, sChainId } = sState
  const [tabIndex, setTabIndex] = useState(0)
  const [tokenIndex, setTokenIndex] = useState(0)
  const [tokenOpen, setTokenOpen] = useState(false)
  const [networkIndex, setNetworkIndex] = useState(0)
  const [networkOpen, setNetworkOpen] = useState(false)
  const [sortIndex, setSortIndex] = useState(0)
  const [sortOpen, setSortOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])

  useEffect(() => {
    const getPools = async () => {
      setLoading(true)
      const resp = await request(`pool/all_pools?isTestnet=${process.env.REACT_APP_DEBUG}`)
      setLoading(false)
      if (resp === null) return
      setData(resp)
    }
    tabIndex === 0 && getPools()
  }, [tabIndex])

  useEffect(() => {
    const getPoolByAddress = async () => {
      setLoading(true)
      const resp = await request(`pool/get_pools_by_address?address=${sMetaMaskAddress}&isTestnet=${process.env.REACT_APP_DEBUG}`)
      setLoading(false)
      if (resp === null) return
      setData(resp)
    }
    tabIndex === 1 && !!sMetaMaskAddress && getPoolByAddress()
  }, [tabIndex, sMetaMaskAddress])
  
  return (
    <div className="p_pools">
      <MContainer
        render={() => (
          <div className="p_pools_head">
            <p className="head_title">Pools</p>
            <p className="head_desc">Just stake some tokens to earn. High APR, low risk.</p>
          </div>
        )}
      />
      <MContainer
        render={() => (
          <div className="p_pools_container">
            <MTableTool 
              options={[{
                label: "All Pools",
                value: 0,
                disabled: false 
              }, {
                label: "Your Pools",
                value: 1,
                disabled: !sMetaMaskAddress 
              }]} 
              value={tabIndex}
              onChange={setTabIndex} 
              buttons={[
                <MButton 
                  key="0" 
                  label={TOKENS[tokenIndex].name} 
                  icon={IMAGE.arrow} 
                  onClick={() => setTokenOpen(true)} 
                />,
                <MButton 
                  key="1" 
                  label={NETWORKS[networkIndex].name} 
                  icon={IMAGE.arrow} 
                  onClick={() => setNetworkOpen(true)} 
                />,
                <MPopover 
                  key="2" 
                  options={SORTS}
                  sortIndex={sortIndex}
                  setSortIndex={setSortIndex}
                  open={sortOpen}
                  setOpen={setSortOpen}
                  child={<MButton label={SORTS[sortIndex].label} icon={IMAGE.sort} />}
                />
              ]}
            />
            <MTable 
              loading={loading}
              options={[{
                title: "Token",
                dataIndex: "tokens",
                flex: 2,
                render: (_) => {
                  return (
                    <MTokens data={_} />
                  )
                },
              }, {
                title: "Network",
                dataIndex: "chainFullName",
                render: (_) => {
                  return (
                    <MChain chain={_} />
                  )
                },
              }, {
                title: "Multiplier",
                dataIndex: "multiplier",
                align: "right",
                format: (_) => {
                  return `${_ || 1}x`
                }
              }, {
                title: "TVL",
                dataIndex: "tvl",
                align: "right",
                format: (_) => {
                  return `$${mathematics.localeString(_)}`
                }
              }, {
                title: "APR",
                dataIndex: "apr",
                align: "right",
                className: "table_apr",
                format: (_) => {
                  return `${mathematics.localeString(_ * 100)}%`
                }
              }]}
              data={data}
              onRowClick={async (_) => {
                if (sChainId === Number(_.chainId)) {
                  withStatePush("/pool1", _)
                } else {
                  const chainId = theString.number16String(_.chainId)
                  const rpcUrl = _.rpcUrl
                  const resp = await exchangeNetwork(chainId, rpcUrl)
                  !!resp && withStatePush("/pool1", _)
                }
              }}
            />
          </div>
        )}
      />
      <MModal 
        open={tokenOpen}
        setOpen={setTokenOpen}
        title="Token"
        isSearch
        options={TOKENS}
        selidx={tokenIndex}
        setSelidx={setTokenIndex}
      />
      <MModal 
        open={networkOpen}
        setOpen={setNetworkOpen}
        title="Network"
        options={NETWORKS}
        selidx={networkIndex}
        setSelidx={setNetworkIndex}
      />
    </div>
  )
}
export default withRouter(IndexPage)