import "./styles.less"

const IndexComponent = (props) => {
  const { className } = props

  return (
    <div className={`m_container ${className}`} >
      <div className="m_container_div">
      {
        props.render()
      }
      </div>
    </div>
  )
}

export default IndexComponent