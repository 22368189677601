import { storage } from "@/util"

const reducer = (state, action) => {
  storage.sets(action.key, action.value)
  switch(action.key) {
    case "sMetaMaskAddress":
      return { ...state, sMetaMaskAddress: action.value }
    case "sChainId":
      return { ...state, sChainId: action.value }
    default:
      return state
  }
}

export default reducer