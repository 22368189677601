import { ethers } from "ethers"

const ETHERS = {

  format(_) {
    return parseFloat(ethers.utils.formatEther(_))
  },

  parse(_) {
    return parseInt(_, 16)
  },

  number(_) {
    return parseFloat(_.toString())
  },

  bignumber(_, decimal = 18) {
    return ethers.utils.parseUnits(_.toString(), decimal)
  }
}

export default ETHERS