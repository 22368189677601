import { useLocation } from "react-router-dom"
import { useConstant, useRoute, useClass } from "@/hook"
import "./styles.less"

const IndexComponent = () => {
  const location = useLocation()
  const { TABBARS, TABBARHOME } = useConstant()
  const { classNames } = useClass()
  const { push } = useRoute()
  const { pathname } = location

  return (
    <div className={classNames([
      "m_tabbar",
      ![...TABBARHOME, ...TABBARS].some(item => item.route === pathname) && "m_tabbar_hidden"
    ])}>
    {
      [...TABBARHOME, ...TABBARS].map((item, index) => {
        return <div key={index} onClick={() => {
          if (JSON.parse(process.env.REACT_APP_DEBUG) || !!item.visible) {
            push(item.route)
          }
        }}>
          <img src={item.route === pathname ? item.icon2 : item.icon} alt="" draggable="false" />
          <p className={classNames([
            pathname === item.route && "active"
          ])}>{ item.label }</p>
        </div>
      })
    }
    </div>
  )
}
export default IndexComponent
