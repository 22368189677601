/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react"
import { withRouter } from "react-router-dom"
import { message } from "antd"
import { MContainer, MFarmForm, MFormItem, MFormItemValue } from "@/component"
import { useWeb3Request } from "@/hook"
import { ETHERS } from "@/ethers"
import { useSessionStore } from "@/store"
import { mathematics } from "@/util"
import moment from "moment"
import { ABI, IMAGE } from "@/assets"
import "./styles.less"

const IndexPage = ({ history }) => {
  const DATA = history.location.state
  const { sState } = useSessionStore()
  const { getWeb3RequestNotError, getWeb3Request, postWeb3Request } = useWeb3Request()
  const { sMetaMaskAddress } = sState
  const [reload, setReload] = useState(false)
  const [lpBalance, setLpBalance] = useState(0)
  const [lpName, setLpName] = useState("")
  const [divideTSPAmount, setDivideTSPAmount] = useState(0)
  const [tspUSD, setTspUSD] = useState(0)
  const [poolTotalValue, setPoolTotalValue] = useState(0)
  const [stakedNumber, setStakedNumber] = useState(0)
  const [totalStakeValue, setTotalStakeValue] = useState(0)
  const [earnedNumber, setEarnedNumber] = useState(0)
  const [estDailyYield, setEstDailyYield] = useState(0)
  const [apr, setApr] = useState(0)
  const [shareOfPool, setShareOfPool] = useState(0)
  const [addLoading, setAddLoading] = useState(false)
  const [removeLoading, setRemoveLoading] = useState(false)
  const [claimLoading, setClaimLoading] = useState(false)

  useEffect(() => {
    const getStakedNumber = async () => {
      const resp = await getWeb3RequestNotError(
        DATA.contractAddress, 
        ABI.STAKINGFARM, 
        "stakeInfo", 
        [sMetaMaskAddress, DATA.lpTokenAddress]
      )
      if (resp === null) return
      setStakedNumber(ETHERS.format(resp.stakeAmount))
    }
    getStakedNumber()
  }, [sMetaMaskAddress, reload])

  useEffect(() => {
    const getTotalStakeValue = async () => {
      const resp = await getWeb3RequestNotError(
        DATA.contractAddress, 
        ABI.STAKINGFARM, 
        "totalStakeValue", 
        []
      )
      if (resp === null) return
      console.log("totalStakeValue", ETHERS.format(resp))
      setTotalStakeValue(ETHERS.format(resp))
    }
    getTotalStakeValue()
  }, [reload])

  useEffect(() => {
    const getProfit = async () => {
      const time = moment().unix()
      console.log("%cFARM => getProfit，调用的合约地址：", "color: red; font-weight: bold;", DATA.contractAddress)
      console.log("%cFARM => getProfit，调用的合约参数：", "color: red; font-weight: bold;", [sMetaMaskAddress, DATA.lpTokenAddress, time])
      const resp = await getWeb3RequestNotError(
        DATA.contractAddress, 
        ABI.STAKINGFARM, 
        "getProfit", 
        [sMetaMaskAddress, DATA.lpTokenAddress, time]
      )
      if (resp === null) return
      setEarnedNumber(ETHERS.format(resp))
    }
    getProfit()
  }, [sMetaMaskAddress, reload])

  useEffect(() => {
    const getBalance = async () => {
      const lpBalance = await getLpBalance()
      setLpBalance(lpBalance)
    }
    getBalance()
  }, [sMetaMaskAddress, reload])

  const getLpBalance = async () => {
    const resp = await getWeb3Request(
      DATA.lpTokenAddress, 
      ABI.ERC20, 
      "balanceOf", 
      [sMetaMaskAddress]
    )
    return resp === null ? 0 : ETHERS.format(resp)
  }

  useEffect(() => {
    const getName = async () => {
      const resp = await getWeb3Request(
        DATA.lpTokenAddress, 
        ABI.ERC20, 
        "name", 
        []
      )
      setLpName(resp)
    }
    getName()
  }, [])

  const onAdd = async () => {
    setAddLoading(true)
    const _lpBalance = await getLpBalance()
    if (_lpBalance === 0) {
      setAddLoading(false)
      return
    }
    const approveResp = await postWeb3Request(
      DATA.lpTokenAddress, 
      ABI.ERC20, 
      "approve", 
      [DATA.contractAddress, ETHERS.bignumber(Math.ceil(_lpBalance), 18)]
    )
    if (approveResp === null) {
      setAddLoading(false)
      return
    } 
    const stakeResp = await postWeb3Request(
      DATA.contractAddress, 
      ABI.STAKINGFARM, 
      "stake", 
      [DATA.lpTokenAddress]
    )
    setAddLoading(false)
    if (stakeResp === null) return
    setReload(!reload)
    message.success("Add successfully")
  }

  const onRemove = async () => {
    setRemoveLoading(true)
    const resp = await postWeb3Request(
      DATA.contractAddress, 
      ABI.STAKINGFARM, 
      "withdraw", 
      [DATA.lpTokenAddress]
    )
    setRemoveLoading(false)
    if (resp === null) return
    setReload(!reload)
    message.success("Remove successfully")
  }

  const onClaim = async () => {
    setClaimLoading(true)
    const resp = await postWeb3Request(
      DATA.contractAddress, 
      ABI.STAKINGFARM, 
      "settlement", 
      [DATA.lpTokenAddress]
    )
    setClaimLoading(false)
    if (resp === null) return
    setReload(!reload)
    message.success("Claim successfully")
  }

  useEffect(() => {
    const getDivideTSPAmount = async () => {
      const resp = await getWeb3RequestNotError(
        DATA.contractAddress, 
        ABI.STAKINGFARM, 
        "divideTSPAmount", 
        []
      )
      const _divideTSPAmount = resp === null ? 0 : ETHERS.format(resp)
      setDivideTSPAmount(_divideTSPAmount)
    }
    getDivideTSPAmount()
  }, [reload])

  useEffect(() => {
    const getTspUSD = async () => {
      const addressResp = await getWeb3RequestNotError(
        DATA.contractAddress, 
        ABI.STAKINGFARM, 
        "tspAddress", 
        []
      )
      if (addressResp === null) return
      const resp = await getWeb3RequestNotError(
        DATA.contractAddress, 
        ABI.STAKINGFARM, 
        "getTokenPrice", 
        [addressResp]
      )
      if (resp === null) return
      setTspUSD(ETHERS.format(resp))
    }
    getTspUSD()
  }, [sMetaMaskAddress, reload])

  useEffect(() => {
    const getPoolTotalValue = async () => {
      const resp = await getWeb3RequestNotError(
        DATA.contractAddress, 
        ABI.STAKINGFARM, 
        "poolTotalValue", 
        [DATA.lpTokenAddress]
      )
      if (resp === null) return
      setPoolTotalValue(ETHERS.format(resp))
    }
    getPoolTotalValue()
  }, [reload])

  useEffect(() => {
    if (stakedNumber === 0) return
    if (divideTSPAmount === 0) return
    const _estDailyYield = stakedNumber / totalStakeValue * divideTSPAmount
    setEstDailyYield(_estDailyYield)
    const _apr = (_estDailyYield * 365) * tspUSD / stakedNumber
    setApr(_apr)
    if (poolTotalValue === 0) return
    const _shareOfPool = stakedNumber / poolTotalValue
    setShareOfPool(_shareOfPool)
  }, [stakedNumber, totalStakeValue, divideTSPAmount, tspUSD, poolTotalValue])
  
  return (
    <div className="p_farm">
      <MContainer
        render={() => (
          <MFarmForm 
            name="Farm"
            tokens={DATA.tokens}
            stakedNumber={stakedNumber}
            earnedNumber={earnedNumber}
            addLoading={addLoading}
            removeLoading={removeLoading}
            claimLoading={claimLoading}
            onAdd={onAdd}
            onRemove={onRemove}
            onClaim={onClaim}
            lpBalance={lpBalance}
            lpName={lpName}
            formItems={[
              <MFormItem 
                key={0}
                label="Est. Daily Yield" 
                valueDom={
                  <MFormItemValue data={[{
                    icon: IMAGE.tsp, 
                    value: `${mathematics.localeString(estDailyYield)} TSP`
                  }]} />
                } 
              />,
              <MFormItem
                key={1} 
                label="APR" 
                value={`${mathematics.localeString(apr * 100)}%`} 
              />,
              <MFormItem 
                key={2} 
                label="Share of Pool" 
                value={`${mathematics.localeString(shareOfPool * 100)}%`}  
              />
            ]}
          />
        )}
      />
    </div>
  )
}
export default withRouter(IndexPage)

  /**
   *  Staked ：stakeInfo[userAddress][lpTokenAddress].stakeAmount
   *  TSP Earn: getProfit(user_, lpTokenAddress, dividing)
   *  add 按钮调用 Stake
   *  remove 按钮调用 withdraw(lpTokenAddress)
   *  claim 按钮调用 settlement(lpTokenAddress)
   *  [当前用户在当前farm中已质押的 LPToken 价值] = stakeInfo[user][lpTokenAddress].stakeValue
   *  Est Daily Reward: [当前用户在当前farm中已质押的 LPToken 价值] / totalStakeValue * divideTSPAmount
   *  APR: (Est Daily Reward * 365) * TSP价格 / [当前用户在当前farm中已质押的 LPToken 价值]
   *  share of pool: [当前用户在当前farm中已质押的 LPToken 价值] / poolTotalValue[lpTokenAddress]
   * **/