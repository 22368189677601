const ABI = [
	{
		inputs: [
			{
				internalType: "uint256",
				name: "time",
				type: "uint256"
			}
		],
		name: "archivePoolInfo",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function"
	},
	{
		inputs: [],
		name: "initialize",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function"
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: "uint8",
				name: "version",
				type: "uint8"
			}
		],
		name: "Initialized",
		type: "event"
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "userAddress",
				type: "address"
			},
			{
				internalType: "address",
				name: "lpTokenAddress",
				type: "address"
			},
			{
				internalType: "uint256",
				name: "time",
				type: "uint256"
			}
		],
		name: "modifyUserClaimTime",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function"
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "address",
				name: "previousOwner",
				type: "address"
			},
			{
				indexed: true,
				internalType: "address",
				name: "newOwner",
				type: "address"
			}
		],
		name: "OwnershipTransferred",
		type: "event"
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "lpTokenAddress",
				type: "address"
			}
		],
		name: "removeLpStakingToken",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function"
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "userAddress",
				type: "address"
			}
		],
		name: "removeUserOrder",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function"
	},
	{
		inputs: [],
		name: "renounceOwnership",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function"
	},
	{
		inputs: [
			{
				internalType: "uint256",
				name: "amount",
				type: "uint256"
			}
		],
		name: "setDivideTSPAmount",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function"
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "lpTokenAddress",
				type: "address"
			},
			{
				internalType: "address",
				name: "ecoTokenAddress",
				type: "address"
			},
			{
				internalType: "address",
				name: "mainTokenAddress",
				type: "address"
			}
		],
		name: "setLpStakingToken",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function"
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "lpTokenAddress",
				type: "address"
			},
			{
				internalType: "uint256",
				name: "weight",
				type: "uint256"
			}
		],
		name: "setLPTokenWeightMap",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function"
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "router",
				type: "address"
			}
		],
		name: "setRouterAddress",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function"
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "a",
				type: "address"
			}
		],
		name: "setSettlementAddress",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function"
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "lpTokenAddress",
				type: "address"
			}
		],
		name: "settlement",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function"
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "address",
				name: "user",
				type: "address"
			},
			{
				indexed: true,
				internalType: "uint256",
				name: "amount",
				type: "uint256"
			}
		],
		name: "Settlement",
		type: "event"
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "a",
				type: "address"
			}
		],
		name: "setTSPAddress",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function"
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "a",
				type: "address"
			}
		],
		name: "setUsdtAddress",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function"
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "lpTokenAddress",
				type: "address"
			}
		],
		name: "stake",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function"
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "address",
				name: "lpToken",
				type: "address"
			},
			{
				indexed: true,
				internalType: "uint256",
				name: "amount",
				type: "uint256"
			},
			{
				indexed: true,
				internalType: "uint256",
				name: "stakeValue",
				type: "uint256"
			}
		],
		name: "Stake",
		type: "event"
	},
	{
		inputs: [],
		name: "testUpdateTime",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function"
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "newOwner",
				type: "address"
			}
		],
		name: "transferOwnership",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function"
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "lpTokenAddress",
				type: "address"
			}
		],
		name: "withdraw",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function"
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "address",
				name: "lpToken",
				type: "address"
			},
			{
				indexed: true,
				internalType: "uint256",
				name: "amount",
				type: "uint256"
			},
			{
				indexed: true,
				internalType: "uint256",
				name: "stakeValue",
				type: "uint256"
			}
		],
		name: "Withdraw",
		type: "event"
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "lpTokenAddress",
				type: "address"
			},
			{
				internalType: "uint256",
				name: "amount",
				type: "uint256"
			}
		],
		name: "calculateStakeValue",
		outputs: [
			{
				internalType: "uint256",
				name: "",
				type: "uint256"
			}
		],
		stateMutability: "view",
		type: "function"
	},
	{
		inputs: [],
		name: "divideTSPAmount",
		outputs: [
			{
				internalType: "uint256",
				name: "",
				type: "uint256"
			}
		],
		stateMutability: "view",
		type: "function"
	},
	{
		inputs: [],
		name: "dividingTime",
		outputs: [
			{
				internalType: "uint256",
				name: "",
				type: "uint256"
			}
		],
		stateMutability: "view",
		type: "function"
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "lpTokenAddress",
				type: "address"
			}
		],
		name: "getLPStakingWeight",
		outputs: [
			{
				internalType: "uint256",
				name: "",
				type: "uint256"
			}
		],
		stateMutability: "view",
		type: "function"
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "user_",
				type: "address"
			},
			{
				internalType: "address",
				name: "lpTokenAddress",
				type: "address"
			},
			{
				internalType: "uint256",
				name: "dividing_",
				type: "uint256"
			}
		],
		name: "getProfit",
		outputs: [
			{
				internalType: "uint256",
				name: "",
				type: "uint256"
			}
		],
		stateMutability: "view",
		type: "function"
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "tokenAddress",
				type: "address"
			}
		],
		name: "getTokenPrice",
		outputs: [
			{
				internalType: "uint256",
				name: "",
				type: "uint256"
			}
		],
		stateMutability: "view",
		type: "function"
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "user",
				type: "address"
			},
			{
				internalType: "address",
				name: "lpTokenAddress",
				type: "address"
			}
		],
		name: "getUserStakedTokenInfoFromPool",
		outputs: [
			{
				internalType: "uint256[]",
				name: "",
				type: "uint256[]"
			}
		],
		stateMutability: "view",
		type: "function"
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "",
				type: "address"
			}
		],
		name: "lpTokenMap",
		outputs: [
			{
				internalType: "address",
				name: "ecoToken",
				type: "address"
			},
			{
				internalType: "address",
				name: "mainToken",
				type: "address"
			}
		],
		stateMutability: "view",
		type: "function"
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "",
				type: "address"
			}
		],
		name: "lpTokenWeightMap",
		outputs: [
			{
				internalType: "uint256",
				name: "",
				type: "uint256"
			}
		],
		stateMutability: "view",
		type: "function"
	},
	{
		inputs: [],
		name: "owner",
		outputs: [
			{
				internalType: "address",
				name: "",
				type: "address"
			}
		],
		stateMutability: "view",
		type: "function"
	},
	{
		inputs: [
			{
				internalType: "uint256",
				name: "",
				type: "uint256"
			}
		],
		name: "poolInfo",
		outputs: [
			{
				internalType: "uint256",
				name: "divideTSPAmount",
				type: "uint256"
			},
			{
				internalType: "uint256",
				name: "totalStakeValue",
				type: "uint256"
			}
		],
		stateMutability: "view",
		type: "function"
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "",
				type: "address"
			}
		],
		name: "poolTotalValue",
		outputs: [
			{
				internalType: "uint256",
				name: "",
				type: "uint256"
			}
		],
		stateMutability: "view",
		type: "function"
	},
	{
		inputs: [],
		name: "routerAddress",
		outputs: [
			{
				internalType: "address",
				name: "",
				type: "address"
			}
		],
		stateMutability: "view",
		type: "function"
	},
	{
		inputs: [],
		name: "settlementAddress",
		outputs: [
			{
				internalType: "address",
				name: "",
				type: "address"
			}
		],
		stateMutability: "view",
		type: "function"
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "",
				type: "address"
			},
			{
				internalType: "address",
				name: "",
				type: "address"
			}
		],
		name: "stakeInfo",
		outputs: [
			{
				internalType: "uint256",
				name: "stakeAmount",
				type: "uint256"
			},
			{
				internalType: "uint256",
				name: "stakeValue",
				type: "uint256"
			},
			{
				internalType: "uint256",
				name: "claimTime",
				type: "uint256"
			},
			{
				internalType: "uint256",
				name: "withdrawableBalance",
				type: "uint256"
			}
		],
		stateMutability: "view",
		type: "function"
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "",
				type: "address"
			},
			{
				internalType: "uint256",
				name: "",
				type: "uint256"
			}
		],
		name: "stakeTokenOrder",
		outputs: [
			{
				internalType: "address",
				name: "lpTokenAddress",
				type: "address"
			},
			{
				internalType: "uint256",
				name: "amount",
				type: "uint256"
			},
			{
				internalType: "uint256",
				name: "weight",
				type: "uint256"
			},
			{
				internalType: "uint256",
				name: "totalValue",
				type: "uint256"
			}
		],
		stateMutability: "view",
		type: "function"
	},
	{
		inputs: [],
		name: "totalStakeValue",
		outputs: [
			{
				internalType: "uint256",
				name: "",
				type: "uint256"
			}
		],
		stateMutability: "view",
		type: "function"
	},
	{
		inputs: [],
		name: "tspAddress",
		outputs: [
			{
				internalType: "address",
				name: "",
				type: "address"
			}
		],
		stateMutability: "view",
		type: "function"
	},
	{
		inputs: [],
		name: "usdtAddress",
		outputs: [
			{
				internalType: "address",
				name: "",
				type: "address"
			}
		],
		stateMutability: "view",
		type: "function"
	}
]

export default ABI