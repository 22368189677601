import { ethers } from "ethers"

export const web3Get = async (contractAddress, contractABI, method, args) => {
  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = provider.getSigner()
  const contract = new ethers.Contract(contractAddress, contractABI, signer)
  try {
    const resp = await contract[method](...args)
    return {
      success: true,
      response: resp
    }
  } catch (error) {
    return {
      success: false,
      response: error
    }
  }
}

export const web3Post = async (contractAddress, contractABI, method, args) => {
  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = provider.getSigner()
  const contract = new ethers.Contract(contractAddress, contractABI, signer)
  try {
    const tx = await contract[method](...args)
    await tx.wait()
    return {
      success: true,
      response: tx
    }
  } catch (error) {
    return {
      success: false,
      response: error
    }
  }
}

export const web3Bridge = async ({contractAddress, contractABI, method, params}) => {
  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = provider.getSigner()
  const contract = new ethers.Contract(contractAddress, contractABI, signer)
  const fromAddress = params.fromAddress
  const dstChainId = params.dstChainId
  const toAddress = ethers.utils.defaultAbiCoder.encode(["address"], [params.address])
  const amount = ethers.utils.parseEther(`${params.amount}`)
  const adapterParams = ethers.utils.solidityPack(["uint16", "uint256"], [1, 200000])
  const callParams = {
    refundAddress: params.address,
    zroPaymentAddress: ethers.constants.AddressZero,
    adapterParams: adapterParams 
  }
  let fees = await contract.estimateSendFee(dstChainId, toAddress, amount, false, adapterParams)
  const gasLimit = ethers.utils.hexlify(1000000)
  try {
    const tx = await contract[method](
      fromAddress,
      dstChainId,
      toAddress,
      amount,
      callParams,
      {
        value: fees[0],
        gasLimit: gasLimit
      }
    )
    await tx.wait()
    return {
      success: true,
      response: tx
    }
  } catch (error) {
    return {
      success: false,
      response: error
    }
  }
}